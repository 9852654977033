import {combineReducers} from 'redux'
import {reduxName as n01, reducer as r01} from '../storages/configuration-storage'
import {reduxName as n02, reducer as r02} from '../storages/app-storage'
import {reduxName as n03, reducer as r03} from '../storages/i18n-storage'

export default combineReducers(
    {
        [n01]: r01,
        [n02]: r02,
        [n03]: r03,
    },
)