import * as t from './action-types'
import {loadTranslationsRequ} from './requests'
import {AnyAction} from 'redux';
import {RootState} from "../../store";
import type {ThunkAction} from 'redux-thunk'
import {ITranslations} from './types'

/**
 * Load translations.
 *
 * @param {string} locale
 */
export const load = (locale: string): ThunkAction<void, RootState, unknown, AnyAction> => (dispatch): Promise<void> => new Promise((resolve, reject) => {
    dispatch({
        type: t.SET_LOADING,
        loading: true,
    });

    loadTranslationsRequ(locale)
        .then((t1) => {
            const translations: ITranslations = {
                ...t1
            }
            dispatch(({
                type: t.SET_TRANSLATIONS,
                locale,
                translations
            }));
            resolve();
        })
        .catch((e) => {
            dispatch({
                type: t.SET_LOADING,
                loading: false,
            });
            console.log('Loading of translations fails.', e)
            reject('Loading of translations fails...');
        })
});