"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSpfMaterial = void 0;
const THREE = require("three");
const createSpfMaterial = (texture = null) => {
    /**vertexshader fuer diagnose-materiel */
    let shaderVertex = [
        'varying vec2 vUv;',
        'varying vec3 vNormal;',
        '',
        'void main() {',
        ' vNormal = normalize(normalMatrix * normal);',
        ' vUv = uv;',
        ' gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);',
        '}'
    ].join('\n');
    /**fragmentshader fuer diagnose-materiel */
    let shaderFragment = [
        'varying vec3 vNormal;',
        'varying vec2 vUv;',
        'uniform sampler2D  myTextur;',
        '',
        'void main() {',
        ' vec4 texColor = texture2D( myTextur, vUv); ',
        ' float dark = pow(vNormal.b, 4.0);',
        ' dark = max(0.0, mix(-0.3, 1.0, dark));',
        '',
        ' gl_FragColor = vec4(texColor.rgb * dark, 1.0);',
        '}',
        ''
    ].join('\n');
    let uniforms = {
        myTextur: { type: 't', value: texture }
    };
    const mat = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: shaderVertex,
        fragmentShader: shaderFragment,
        transparent: false
    });
    mat.name = 'm_spf';
    mat.needsUpdate = true;
    return mat;
};
exports.createSpfMaterial = createSpfMaterial;
