import React from 'react';
import './light-box-popup.scss'
import ImageUploadInput from '../input/image-upload-input';
import {LightBoxMode} from "../../redux/storages/configuration-storage";
import {IconText, IconImage} from "../icons/svgs";
import IconTextButton from "../buttons/icon-text-button";
import PopupContent from './popup-content';
import Switcher, {SwitchMode} from '../switcher';
import LightBoxTextInput from '../input/light-box-text-input';

interface IProps {
    mode: LightBoxMode,
    text: string,
    image?: File,
    imageLastUploadError: boolean,
    onTextChange: (text: string) => void,
    onSelectMode: (mode: LightBoxMode) => void,
    onImageRemove: (image: File) => void,
    onImageUpload: (image: File) => void,
    t: (key: string) => string,
}

const LightBoxPopup: React.FC<IProps> = ({
                                             mode,
                                             text,
                                             image,
                                             imageLastUploadError,
                                             onTextChange,
                                             onSelectMode,
                                             onImageRemove,
                                             onImageUpload,
                                             t,
                                             ...props
                                         }) => {

    return (
        <PopupContent title={t("lightbox.editor.main.title")}>
            <Switcher mode={SwitchMode.auto} className="cl-lightboxPopup-switch">
                <IconTextButton
                    isSelected={mode === LightBoxMode.text}
                    label={t("lightbox.editor.button.tab.text")}
                    onClick={() => {
                        onSelectMode(LightBoxMode.text)
                    }}
                >
                    <IconText/>
                </IconTextButton>

                <IconTextButton
                    isSelected={mode === LightBoxMode.image}
                    label={t("lightbox.editor.button.tab.custom.image")}
                    onClick={() => {
                        onSelectMode(LightBoxMode.image)
                    }}
                >
                    <IconImage/>
                </IconTextButton>
            </Switcher>
            {
                mode === LightBoxMode.text && (
                    <LightBoxTextInput
                        className="cl-lightboxPopup-text"
                        text={text}
                        onTextBlur={onTextChange}
                    />
                )
            }
            {
                mode === LightBoxMode.image && (
                    <ImageUploadInput
                        className="cl-lightboxPopup-image"
                        t={t}
                        lastUploadError={imageLastUploadError}
                        lastUploadFile={image}
                        selectDisabled={true}
                        onRemove={onImageRemove}
                        onUpload={onImageUpload}
                    />
                )
            }
        </PopupContent>
    )
}

export default LightBoxPopup