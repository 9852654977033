import React from 'react';
import classNames from 'classnames';
import './switcher.scss'

export enum SwitchMode {
    horizontal = 'horizontal',
    vertical = 'vertical',
    auto = 'auto',
}

interface IProps {
    mode?: SwitchMode,
    [index: string]: any,
}

const Switcher: React.FC<IProps> = ({mode = SwitchMode.auto, className, children, ...props}) => {
    const classes = classNames('cl-switch', 'cl-switch-' + mode, className);

    return (
        <div className={classes} {...props}>
            {children}
        </div>
    );
}

export default Switcher