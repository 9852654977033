import { Option, ProductWithContext, YamlDatasetService } from '@canvas-logic/engine';
import { IConfigurationModel } from '../configuration-service';

class Restorer {
  readonly datasetService: YamlDatasetService;

  constructor(datasetService: YamlDatasetService) {
    this.datasetService = datasetService;
  }

  public restore(productWithContext: ProductWithContext, model: IConfigurationModel): boolean {
    const optCardDispenser = this.findOption('CardDispenser', model.cardDispenser ? 'cd_true' : 'cd_false');
    if (!optCardDispenser) {

      return false;
    }

    const optPrinterShredder = this.findOption('PrinterShredder', model.printerShredder ? 'prishred_true' : 'prishred_false');
    if (!optPrinterShredder) {

      return false;
    }

    const optThermoPrinter = this.findOption('ThermoPrinter', model.thermoPrinter ? 'pritherm_true' : 'pritherm_false');
    if (!optThermoPrinter) {

      return false;
    }

    const optKeyboard = this.findOption('Keyboard', model.keyboard ? 'keyb_true' : 'keyb_false');
    if (!optKeyboard) {

      return false;
    }

    const optScanner = this.findOption('Scanner', model.scanner ? 'scan_true' : 'scan_false');
    if (!optScanner) {

      return false;
    }

    const optScreenPrivacyFilter = this.findOption('ScreenPrivacyFilter', model.screenPrivacyFilter ? 'spf_true' : 'spf_false');
    if (!optScreenPrivacyFilter) {

      return false;
    }

    const optLightBox = this.findOption('LightBox', model.lightBox ? 'lb_true' : 'lb_false');
    if (!optLightBox) {

      return false;
    }

    const optMaterial = this.findOption('Material', model.material);
    if (!optMaterial) {

      return false;
    }

    const optDouble = this.findOption('Double', model.double ? 'dbl_true' : 'dbl_false');
    if (!optDouble) {

      return false;
    }

    const optMount = this.findOption('Mount', model.mount);
    if (!optMount) {

      return false;
    }

    const prodModel = productWithContext.product.model;
    prodModel.cardDispenser.value = optCardDispenser;
    prodModel.printerShredder.value = optPrinterShredder;
    prodModel.thermoPrinter.value = optThermoPrinter;
    prodModel.keyboard.value = optKeyboard;
    prodModel.scanner.value = optScanner;
    prodModel.screenPrivacyFilter.value = optScreenPrivacyFilter;
    prodModel.lightBox.value = optLightBox;
    prodModel.material.value = optMaterial;
    prodModel.double.value = optDouble;
    prodModel.mount.value = optMount;

    return true;
  }

  private findOption(name: string, id: string) {
    const map = new Map<string, Option<any>>();
    const options = this.datasetService.getOptions(name) as Option<any>[];
    options.forEach(option => map.set(option._id!, option));
    const option = map.get(id);
    if (!option) {
      console.log(`ERROR: Could not find ${name} ${id}.`);

      return;
    }

    return option;
  }
}

export default Restorer;