"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileServiceRequests = void 0;
const api_fetch_1 = require("./api-fetch");
const url_1 = require("../utils/url");
class FileServiceRequests {
    constructor(host, apiKey) {
        this.host = host;
        this.apiKey = (0, url_1.stripTrailingSlash)(apiKey);
    }
    hasFile(fileName) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log('upload');
            const url = `${this.host}/has-file/${fileName}?api_key=${this.apiKey}`;
            return yield (0, api_fetch_1.apiFetch)(url, {
                method: 'GET'
            });
        });
    }
    ;
    upload(fileName, blob) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log('upload');
            const url = `${this.host}/upload/${fileName}?api_key=${this.apiKey}`;
            const fd = new FormData();
            fd.append('file', blob);
            return yield (0, api_fetch_1.apiFetch)(url, {
                method: 'POST',
                body: fd
            });
        });
    }
    ;
    download(fileName) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${this.host}/download/${fileName}?api_key=${this.apiKey}`;
            return yield (0, api_fetch_1.apiFetch)(url, {
                method: 'GET',
            });
        });
    }
    getDownloadUrl(fileName) {
        return `${this.host}/download/${fileName}?api_key=${this.apiKey}`;
    }
    ;
    getModelViewerUrl(fileName, arPlacement = 'floor') {
        return `${this.host}/model-viewer/${fileName}?api_key=${this.apiKey}&ar_placement=${arPlacement}`;
    }
    ;
}
exports.FileServiceRequests = FileServiceRequests;
