import {useEffect} from "react";
import {IDimensions} from "./use-size";
import {viewerService} from '../services/viewer-service';

export const useViewerResize = (enabled: boolean, dimension: IDimensions) => {
    useEffect(() => {
        if (enabled) {
            viewerService.viewer.resize(dimension);
        }
    }, [enabled, dimension])
}