import './start-debug-button.scss'
import * as React from 'react';
import IconButton from "./icon-button";
import classNames from "classnames";

interface iProps {
    children: React.ReactNode,
    onClick: any,
    title: string,
    className?: string,
}

const StartDebugButton:React.FC<iProps> = ({children, title, className, ...props}) => {
    const classes = classNames('cl-start-debug-button', className)

    return (
        <IconButton
            {...props}
            className={classes}
            title={title}
            isSelected={false}
        >
            {children}
        </IconButton>

    )
}


export default StartDebugButton;
