import './popup-content.scss'
import React from 'react';
import classNames from "classnames";

interface iProps {
    title?: string,
    className?: string,
    [index: string]: any,
}

const PopupContent: React.FC<iProps> = ({title = '', className='', children, ...props}) => {

    const classes = classNames(
        "cl-popupContent",
        className
    )

    return (
        <div className={classes} {...props}>
            <div className="cl-popupContent-inner">
                {
                    title.length > 0 && (
                        <div className="cl-popupContent-title">
                            {title}
                        </div>
                    )
                }
                {children}
            </div>
        </div>
    );
};

export default PopupContent;
