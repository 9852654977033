"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAndAppendCanvas = void 0;
const createAndAppendCanvas = (parent) => {
    parent.textContent = '';
    const canvas = document.createElement('canvas');
    parent.appendChild(canvas);
    return canvas;
};
exports.createAndAppendCanvas = createAndAppendCanvas;
