import {useEffect, useState} from "react";
import {viewerService, IAnnotationResult} from '../services/viewer-service';

interface IItem {
    transform: string,
    display: string,
}

export interface IStyles {
    [index: string]: IItem,
}

const mapStyles = (annotation: IAnnotationResult): IItem => ({
    transform: 'translate(' + annotation.left + 'px, ' + annotation.top + 'px)',
    display: annotation.visible ? 'block' : 'none'
})

export const useAnnotationStyles = () => {
    const [styles, setStyles] = useState<IStyles>();

    useEffect(() => {
        viewerService.viewer.annotationOnChange = (annotations: IAnnotationResult[]) => {
            const styles: IStyles = {};
            annotations.forEach((anno) => {
                styles[anno.name] = mapStyles(anno);
            })
            setStyles(styles);
        };
    }, [])

    return styles
}