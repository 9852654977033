import React, {useCallback, useEffect, useState} from 'react';
import "./debug-popup.scss"
import AssetsUploadInput from "../input/assets-upload-input";
import {useAppSelector} from "../../redux/store";
import * as cnfStorage from '../../redux/storages/configuration-storage'
import {viewerService} from "../../services/viewer-service";

interface IProps {}

let materialsUrl: any = undefined

const AssetsUploadMaterials: React.FC<IProps> = () => {
    const conf = useAppSelector(cnfStorage.selectors.getConfiguration);
    const [assetUrl, setAssetUrl] = useState<any>(materialsUrl)

    useEffect(() => {
            return () => {
                materialsUrl = assetUrl;
            };
        }, [assetUrl]
    )

    const onUpload = useCallback(async (file: File) => {
        setAssetUrl(file.name)

        if (undefined === conf) {

            return;
        }
        const url = URL.createObjectURL(file);

        await viewerService.reinit({}, {
            viewer: {
                materialsUrl: url,
            }
        })

        viewerService.showConfiguration(conf)
    },[conf])

    return (
        <AssetsUploadInput
            onUpload={onUpload}
            name={"materials"}
            fileName={assetUrl}
        />
    );
}

export default AssetsUploadMaterials
