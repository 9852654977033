import React, {useState} from 'react';
import './buttons/share-feedback-button.scss';
import {useTrans} from "../redux/storages/i18n-storage";
import {IconGenerateLink} from "./icons/svgs";
import {useAppSelector} from "../redux/store";
import * as cnfStorage from '../redux/storages/configuration-storage'
import { CURRENT_VERSION, getShareService } from '../services/share-service';
import ShareFeedbackButton from "./buttons/share-feedback-button";
import ButtonFeedbackHint from './button-feedback-hint';
import { IConfigurationModel } from '../services/configuration-service';
import { getEmbeddedService } from '../services/embedded-service';

interface IProps {
    [index: string]: any,
}

const ShareLinkButtonContainer: React.FC<IProps> = ({ ...props}) => {
    const t = useTrans();
    const model = useAppSelector(cnfStorage.selectors.getConfigurationModel)
    const [running, setRunning] = useState<boolean>(false);
    const [showFeedback, setShowFeedback] = useState<boolean>(false)

    const handleClick = async () => {
        setShowFeedback(false)
        setRunning(true);
        if(model) {
            let link = '';
            try {
                const shareSrv = await getShareService();
                link = await shareSrv.makeLink({
                    v: CURRENT_VERSION,
                    model: model as IConfigurationModel,
                });
            }
            catch(e) {
                console.log('ERROR: Can not store configuration.');
                console.log(e);
            }
            const embeddedSrv = await getEmbeddedService();
            if(embeddedSrv.embedded) {
                embeddedSrv.writeClipboardText(link);
            }
            else {
                await window.navigator.clipboard.writeText(link);
            }
            setShowFeedback(true)
            setRunning(false);
        }
    }

    const closeInfo = () => {
        setShowFeedback(false)
    }

    return (
      <>
          <ShareFeedbackButton
            title={t('link.generator.button.text')}
            iconNode={<IconGenerateLink />}
            running={running}
            onClick={handleClick}
          >
              {
                showFeedback && (
                  <ButtonFeedbackHint onClose={closeInfo}>
                      {t('link.generator.button.ready.text')}
                  </ButtonFeedbackHint>
                )
              }
          </ShareFeedbackButton>
      </>
    );
}

export default ShareLinkButtonContainer