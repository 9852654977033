import './icon-button.scss';
import * as React from 'react';
import classNames from "classnames";

interface iProps {
    children: React.ReactNode,
    className?: string,
    title: string,
    onClick: any,
    isSelected: boolean,
    disabled?: boolean,
}

const noop = () => {}

const IconButton: React.FC<iProps> = ({
                                          children,
                                          onClick,
                                          title,
                                          isSelected,
                                          className,
                                          disabled = false,
                                          ...props
                                      }) => {

    // let isSelectedTile = isSelected ? " cl-selected-tile" : ""
    // let tileClass = iconClassName + isSelectedTile

    const classes = classNames(
        "cl-iconButton",
        isSelected ? " cl-selected-tile" : "",
        className
    )

    return (
        <div className={classes} {...props}>
            <div title={title} onClick={disabled ? noop : onClick}>
                {children}
            </div>
        </div>)
}


export default IconButton;
