"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfiguratorUrlHelper = exports.PARAM_NAME = void 0;
exports.PARAM_NAME = 'cnf';
class ConfiguratorUrlHelper {
    constructor() { }
    getKey() {
        return (new URLSearchParams(this.getSearch())).get(exports.PARAM_NAME);
    }
    createLink(configKey) {
        const location = this.getLocation();
        const urlParams = new URLSearchParams(this.getSearch());
        if (configKey === undefined) {
            urlParams.delete(exports.PARAM_NAME);
        }
        else {
            urlParams.set(exports.PARAM_NAME, configKey);
        }
        const search = urlParams.toString();
        const hash = this.getHash();
        return location.origin + location.pathname + (search.length > 0 ? '?' + search : '') + hash;
    }
    getLocation() {
        return window.location;
    }
    getSearch() {
        const location = this.getLocation();
        return location.search;
    }
    getHash() {
        const location = this.getLocation();
        return location.hash.replace(/\?.*/, '');
    }
}
exports.ConfiguratorUrlHelper = ConfiguratorUrlHelper;
