"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.gltfLoad = void 0;
const GLTFLoader_1 = require("three/examples/jsm/loaders/GLTFLoader");
const gltfLoad = (path, onProgress = () => { }) => {
    return new Promise((resolve, reject) => {
        // const cached = cache.get(path);
        // if(cached) {
        //     console.log('Read file from cache', path)
        //     resolve(cached);
        //
        //     return;
        // }
        new GLTFLoader_1.GLTFLoader()
            .load(path, (gltf) => {
            // cache.set(path, gltf.scene);
            resolve(gltf.scene);
        }, onProgress, reject);
    });
};
exports.gltfLoad = gltfLoad;
