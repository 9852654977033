import React from 'react';
import styles from './debug-models-export.module.scss';
import * as viewerSrv from '../../services/viewer-service';
import { downloadBlob } from '@canvas-logic/configurator-base/lib/utils/file';

const onGLBExport = async () => {
  const blob = await viewerSrv.viewerService.exportGLTF();
  downloadBlob(blob, 'model.glb');
};

const onUSDZExport = async () => {
  const blob = await viewerSrv.viewerService.exportUSDZ();
  downloadBlob(blob, 'model.usdz');
};

const DebugModelsExportContainer: React.FC = (props) => {

  return (
    <DebugModelsExport
      onGLBExport={onGLBExport}
      onUSDZExport={onUSDZExport}
    />
  );
};
export default DebugModelsExportContainer;

interface IProps {
  onGLBExport: () => void,
  onUSDZExport: () => void,
}

const DebugModelsExport: React.FC<IProps> = (props) => {

  return (
    <div className={styles.wrapper}>
      <button onClick={props.onGLBExport}>Export as glb file</button>
      <button onClick={props.onUSDZExport}>Export as usdz file</button>
    </div>
  );
};