"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dataUrlToFile = exports.fileToDataUrl = exports.downloadBlob = void 0;
exports.downloadBlob = (function () {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = 'none';
    return function (blob, fileName) {
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}());
const fileToDataUrl = (file) => new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.onerror = (error) => {
        reject(error);
    };
});
exports.fileToDataUrl = fileToDataUrl;
const dataUrlToFile = (dataUrl, fileName) => {
    const arr = dataUrl.split(',');
    if (arr.length < 2) {
        throw new Error('Could not convert to file.');
    }
    // @ts-ignore
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
};
exports.dataUrlToFile = dataUrlToFile;
