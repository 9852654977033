import React, {useEffect, useRef, useState} from 'react';
import TextAreaInput from './text-area-input';
import './light-box-text-input.scss';
import {setCaretPosition} from '../../services/html-service';
import classNames from 'classnames';

interface IProps {
    text: string,
    onTextBlur: (text: string) => void,
    [index: string]: any,
}

const LightBoxTextInput:React.FC<IProps> = ({text, onTextBlur, className, ...props}) => {
    const ref = useRef<HTMLTextAreaElement>(null);

    const [value, setValue] = useState<string>('');
    useEffect(() => {
        setValue(text);
        if (ref.current) {
            setCaretPosition(ref.current, text.length);
        }
    }, [text])

    const classes = classNames('cl-lightboxTextInput', className);

    const changeHandler = (event: React.FormEvent<HTMLTextAreaElement>) => {
        if (event.currentTarget) {
            setValue(event.currentTarget.value);
        }
    }

    const blurHandler = () => {
        onTextBlur(value);
    }

    return (
        <TextAreaInput
            {...props}
            ref={ref}
            spellCheck={false}
            className={classes}
            value={value}
            onBlur={blurHandler}
            onChange={changeHandler}
        />
    )
}

export default LightBoxTextInput