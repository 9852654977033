import React from 'react';
import './text-button.scss';
import classNames from "classnames";

interface IProps {
    as?: string,
    className?: string,
    size?: ButtonSize,
    bordered?: boolean,
    centered?: boolean,

    [index: string]: any,
}

export enum ButtonSize {
    medium = 'medium',
    large = 'large',
}

const TextButton: React.FC<IProps> = ({
                                          as = 'div',
                                          size = ButtonSize.medium,
                                          bordered = false,
                                          centered = false,
                                          children,
                                          className, ...props
                                      }) => {
    const classes = classNames(
        'cl-text-button',
        `cl-text-button-${size}`,
        bordered && 'cl-text-button-bordered',
        centered && 'cl-text-button-centered',
        className
    );

    return React.createElement(as, {className: classes, ...props}, children)
    // return (
    //     <div className={classes} {...props}>
    //         {children}
    //     </div>
    // );
}

export default TextButton;