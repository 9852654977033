import {createSelector} from 'reselect'
import {reduxName}      from './constants'
import type {RootState}      from '../../store';
import {IState} from "./reducer";

const getAll = (state: RootState): IState => (state[reduxName]);

export const zooming = createSelector(
    getAll,
    all => all.zooming
)

export const getSelectedZoom = createSelector(
    getAll,
    all => all.selectedZoom
);

export const zoomed = createSelector(
    getSelectedZoom,
    selectedZoom => selectedZoom !== undefined
)

export const rotating = createSelector(
    getAll,
    all => ({
        left: all.rotatingLeft,
        right: all.rotatingRight,
    })
)
