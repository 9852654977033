export class Sanitizer {
    private readonly checkWidth: (str: string) => boolean;

    public constructor(checkWidth: (str: string) => boolean) {
        this.checkWidth = checkWidth;
    }

    private static toLines(text: string): string[] {
        // to lines
        let lines = text.split("\n");

        // trim lines and remove white spaces and duplicate spaces
        lines = lines.map(l => {
            l = l.replace(/\s/g, ' ');
            l = l.replace(/ +/g, ' ');
            l = l.trim();

            return l;
        });

        // remove empty trailing lines
        while (lines.length > 0 && lines[lines.length - 1].length === 0) {
            lines.pop();
        }

        return lines;
    }

    private split(str: string): string[] {
        if (this.checkWidth(str)) {

            return [str, ''];
        }

        let splitIndex = -1;
        for (let i = str.length; i >= 0; i--) {
            const splittable = i === str.length || str[i] === ' ';
            const first = str.slice(0, i);
            const second = str.slice(i);

            if (this.checkWidth(first)) {
                if(splitIndex === -1) {
                    splitIndex = i;
                }
                if (splittable) {

                    return [first, second.trim()];
                }
            }
        }

        if(splitIndex === -1) {

            return ['', str];
        }

        return [
            str.slice(0, splitIndex), str.slice(splitIndex).trim()
        ];
    }

    public sanitize = (text: string): string[] => {
        let lines = Sanitizer.toLines(text);

        const inLines: string[] = [lines[0]];
        const outLines = [];
        let i = 0;
        while (inLines.length > 0) {
            const line = inLines.shift() ?? '';
            const [first, second] = this.split(line);
            outLines.push(first);
            i++;
            if (i < lines.length) {
                inLines.push(second.length > 0 ? second +  ' ' + lines[i] : lines[i]);
            } else if (second.length > 0) {
                inLines.push(second);
            }
        }

        return outLines.slice(0, 3);
    }
}