import {useEffect} from "react";

type IHandler = () => void;

export const useEscKeyDown = (handler: IHandler, enabled: boolean = true) => {
    useEffect(() => {
        const close = (e: any) => {
            if (e.keyCode === 27) {
                enabled && handler()
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [handler, enabled]);
}

