"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CanvasTexture = void 0;
const THREE = require("three");
const merge_configurations_1 = require("../utils/merge-configurations");
const defaultConfiguration = {
    canvas: {
        width: 1024,
        height: 1024,
    },
    context: {
        textAlign: 'center',
        fillStyle: '#000000',
        textBaseline: 'middle',
        font: '110px Rubik-Regular',
    },
    texture: {
        flipY: false,
    }
};
class CanvasTexture {
    constructor(configuration = {}) {
        this.configuration = (0, merge_configurations_1.mergeConfigurations)(defaultConfiguration, configuration);
        const context = document
            .createElement('canvas')
            .getContext('2d');
        if (null === context) {
            console.log('ERROR: Could not create canvas context.');
            return;
        }
        this.context = context;
        this.context.canvas.width = this.configuration.canvas.width;
        this.context.canvas.height = this.configuration.canvas.height;
        this.context.textAlign = this.configuration.context.textAlign;
        this.context.fillStyle = this.configuration.context.fillStyle;
        this.context.textBaseline = this.configuration.context.textBaseline;
        this.context.font = this.configuration.context.font;
        this.texture = new THREE.CanvasTexture(this.context.canvas);
        this.texture.flipY = this.configuration.texture.flipY;
    }
}
exports.CanvasTexture = CanvasTexture;
