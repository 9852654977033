import * as t from './action-types';
import type {AnyAction} from 'redux';
import type {ThunkAction} from 'redux-thunk'
import {viewerService, Tween, AnnotationConfigName} from '../../../services/viewer-service';
import {RootState} from "../../store";
import * as cnfStorage from '../configuration-storage'

export const zoom = (selectedZoom: AnnotationConfigName | undefined): ThunkAction<void, RootState, unknown, AnyAction> => (dispatch, getState) => {
    const configuration = cnfStorage.selectors.getConfiguration(getState())

    if(!configuration) {

        return;
    }

    dispatch({
        type: t.START_ZOOM,
        selectedZoom
    });


    let promise;
    switch (selectedZoom) {
        case AnnotationConfigName.zoomPosDisplay:
            promise = viewerService.tweenTo(configuration, Tween.Zoom01);
            break;
        case AnnotationConfigName.zoomPosLight:
            promise = viewerService.tweenTo(configuration, Tween.Zoom02);
            break;
        case undefined:
            promise = viewerService.tweenTo(configuration, Tween.Home);
            break;
        default:
            throw new Error('Zoom type ' + selectedZoom + ' not supported.');
    }

    promise
        .then(() => {
            dispatch({
                type: t.FINISH_ZOOM,
            })
        })
        .catch((e: any) => {
            console.log('Zoom failed', e)
        })
};

export const rotate = (left: boolean = true): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    dispatch({
        type: t.START_ROTATING,
        left,
    });

    try {
        await viewerService.tweenRotate(left);
        dispatch({
            type: t.FINISH_ROTATING,
            rotating: false,
        });
    } catch (e) {
        console.log('Rotation failed', e)
    }
};