"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Annotation = void 0;
const three_1 = require("three");
/**
 * Annotation Class
 *
 * init : const testAnno = new Annotation(scene.getObjectByName('Empty'), camera, 53.14);
 *
 * updatePoints:
 * camera.updateMatrixWorld();
 * testAnno.update();
 * let vx = testAnno.projVec.x * (canvas.width );
 * let vy = testAnno.projVec.y * (canvas.height);
 * if (testAnno.Visible) {}
 *
 * */
class Annotation {
    /**
     *
     * @param {string} configName
     * @param {Object3D} empty - in Blender definiertes Empty
     * @param {PerspectiveCamera} camera - THREE Camera
     * @param {float} viewAngle - oeffnungswinkel in grad -> Plane : 180
     */
    constructor(configName, empty, camera, viewAngle) {
        this._visible = false;
        this.configName = configName;
        this.forwardVec = new three_1.Vector3(0, 1, 0);
        this.camVec = new three_1.Vector3();
        /**x: 0-1 -> left-right - y: 0-1 -> top-bottom */
        this.projVec = new three_1.Vector3();
        this.emptyPosition = new three_1.Vector3();
        this.emptyDirection = new three_1.Vector3();
        this.emptyQuat = new three_1.Quaternion();
        this.empty = empty;
        this.camera = camera;
        this.clampAngle = viewAngle * (Math.PI / 360); // halber Winkel
    }
    get viewAngle() {
        return this.clampAngle * (360 / Math.PI);
    }
    set viewAngle(newDegree) {
        this.clampAngle = newDegree * (Math.PI / 360); // halber Winkel
    }
    get x() {
        return this.projVec.x;
    }
    get y() {
        return this.projVec.y;
    }
    get visible() {
        return this._visible;
    }
    update() {
        this.camera.getWorldPosition(this.camVec);
        this.empty.getWorldPosition(this.emptyPosition);
        this.camVec.sub(this.emptyPosition);
        this.empty.getWorldQuaternion(this.emptyQuat);
        this.emptyDirection.copy(this.forwardVec);
        this.emptyDirection.applyQuaternion(this.emptyQuat);
        this.emptyPosition.project(this.camera);
        this.projVec.x = (0.5 + this.emptyPosition.x / 2);
        this.projVec.y = (0.5 - this.emptyPosition.y / 2);
        if (this.emptyPosition.x < -1.0 || this.emptyPosition.x > 1.0) {
            this._visible = false;
        }
        else if (this.emptyPosition.y < -1.0 || this.emptyPosition.y > 1.0) {
            this._visible = false;
        }
        else if (this.emptyPosition.z < 0.0 || this.emptyPosition.z > 1.0) {
            this._visible = false;
        }
        else if (this.emptyDirection.angleTo(this.camVec) > this.clampAngle) {
            this._visible = false;
        }
        else {
            this._visible = true;
        }
    }
}
exports.Annotation = Annotation;
