"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsdzExporter = void 0;
const merge_configurations_1 = require("../utils/merge-configurations");
const USDZExporter_1 = require("three/examples/jsm/exporters/USDZExporter");
const defaultConfiguration = {
    options: {},
    root: 'root',
    hidden: [],
    scale: 1.0,
};
class UsdzExporter {
    constructor(scene, configuration = {}) {
        this.scene = scene;
        this.configuration = (0, merge_configurations_1.mergeConfigurations)(defaultConfiguration, configuration);
        this.origScaleVector = null;
    }
    export() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                const root = this.scene.getObjectByName(this.configuration.root);
                if (root) {
                    this.hide(this.configuration.hidden, false);
                    this.scale(root, false);
                    try {
                        // export
                        const exporter = new USDZExporter_1.USDZExporter();
                        const arraybuffer = yield exporter.parse(root);
                        const blob = new Blob([arraybuffer], Object.assign(Object.assign({}, this.configuration.options), { type: 'model/vnd.pixar.usd' }));
                        resolve(blob);
                        this.scale(root, true);
                        this.hide(this.configuration.hidden, true);
                    }
                    catch (e) {
                        console.log(`ERROR: Can't export.`, e);
                        reject(e);
                    }
                }
                else {
                    console.log(`ERROR: Object with name ${this.configuration.root} not found.`);
                    reject(new Error(`ERROR: Object with name ${this.configuration.root} not found.`));
                }
            }));
        });
    }
    scale(obj, restore) {
        if (!restore) {
            this.origScaleVector = obj.scale.clone();
            obj.scale.multiplyScalar(this.configuration.scale);
            obj.updateMatrixWorld();
        }
        else {
            if (!this.origScaleVector) {
                console.log('ERROR: Original scale values are not stored. Did you call scale with restore==false at first?');
                return;
            }
            obj.scale.copy(this.origScaleVector);
            obj.updateMatrixWorld();
            this.origScaleVector = null;
        }
    }
    hide(names, restore) {
        names.forEach((name) => {
            const object = this.scene.getObjectByName(name);
            if (object) {
                object.visible = restore;
            }
            else {
                console.log(`ERROR: Object with name ${name} not found.`);
            }
        });
    }
}
exports.UsdzExporter = UsdzExporter;
