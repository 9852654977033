"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imageLoad = void 0;
const cache = new Map();
const imageLoad = (path) => {
    return new Promise((resolve, reject) => {
        const cached = cache.get(path);
        if (cached) {
            console.log('Read file from cache', path);
            resolve(cached);
            return;
        }
        const img = new Image();
        img.onload = () => {
            cache.set(path, img);
            resolve(img);
        };
        img.src = path;
        img.onerror = reject;
    });
};
exports.imageLoad = imageLoad;
