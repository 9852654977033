import React from 'react';
import classNames from 'classnames';
import './text-input-text-area-input.scss';

interface IProps {
    className?: string,
    [index: string]: any,
}

const TextInput: React.FC<IProps> = ({className, ...props}) => {
    const classes = classNames('cl-textInputTextAreaInput', className);

    return (
        <input
            {...props}
            className={classes}
            type="text"
        />
    );
}

export default TextInput;