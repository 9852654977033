import * as React from 'react';
import './LoadingCircle.scss'

interface IProps {
    style?: object
}

const LoadingAnimation: React.FC<IProps> = (props) => {

    return (
        <div className="cl-loading-layover" {...props}>
            <div className="cl-loading-ring">
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
    )
}

export default LoadingAnimation
