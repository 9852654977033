import * as React from 'react';
import './loading-ring.scss'

interface IProps {
    size?: number,
    style?: object
}

const LoadingRing: React.FC<IProps> = ({
                                           size = 112,
                                           ...props
                                       }) => {

    return (
        <div className="cl-loading-ring">
            <div style={{width: size, height: size}}/>
            <div style={{width: size, height: size}}/>
            <div style={{width: size, height: size}}/>
            <div style={{width: size, height: size}}/>
        </div>
    )
}

export default LoadingRing
