import React from 'react';
import "./debug-popup.scss"

interface IProps {
    children: any,
}

const DebugPopup: React.FC<IProps> = ({children}) => {

    return (
        <div className="cl-debug-popup">
            {children}
        </div>
    );
}

export default DebugPopup