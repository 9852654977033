import * as libSrv from '@canvas-logic/configurator-base/lib/services/share-service';
import { IConfigurationModel } from '../configuration-service';
import { IRestoreObject } from '@canvas-logic/configurator-base/lib/services/share-service/serializer';
import { IMapperResult, Mapper } from './mapper';

export class ShareService {
  constructor(
    private libShareSrv: libSrv.ShareService<IMapperResult>,
    private mapper = new Mapper()
  ) {}

  async fetch(): Promise<IRestoreObject<IConfigurationModel> | false> {
    const res: IRestoreObject<IMapperResult> | false = await this.libShareSrv.fetch();
    if(res === false) {

      return res;
    }

    return {
      ...res,
      model: this.mapper.fromResult(res.model),
    }
  }

  async makeLink(obj: IRestoreObject<IConfigurationModel>): Promise<string> {

    return this.libShareSrv.makeLink({
      ...obj,
      model: await this.mapper.toResult(obj.model),
    });
  }

  reload() {
    this.libShareSrv.reload();
  }
}