import React, { useEffect, useRef, useState } from 'react';
import './button-feedback-hint.scss';
import { useOutsideClick } from '../hooks/use-outside-click';
import classNames from 'classnames';

type Position = 'right' | 'top';

interface IProps {
  className?: string,
  position?: Position,
  onClose: () => void,
  durance?: number,
  children: React.ReactNode,
  textSelectable?: boolean,

  [index: string]: any,
}

const ButtonFeedbackHint: React.FC<IProps> = ({
                                                className,
                                                position = 'right',
                                                durance = 3000,
                                                children,
                                                onClose,
                                                textSelectable = false,
                                                ...props
                                              }) => {
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => {
    timer && clearTimeout(timer);
    onClose();
  });
  const [timer, setTimer] = useState<any>();
  const classes = classNames(
    'cl-button-feedback-info',
    position === 'top' && 'cl-button-feedback-info-top',
    position === 'right' && 'cl-button-feedback-info-right',
    textSelectable ? 'cl-button-feedback-info-selectable' : ''
  );

  useEffect(() => {
    timer && clearTimeout(timer);
    setTimer(setTimeout(onClose, durance));

    return () => {
      timer && clearTimeout(timer);
    };
  }, [onClose, durance]);

  return (
    <div {...props} ref={ref} className={classes}>
            <span className="cl-button-feedback-info-text">
              {children}
            </span>
    </div>
  );
};

export default ButtonFeedbackHint;