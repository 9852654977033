"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmptyAnnotationManager = void 0;
const annotation_1 = require("./annotation");
const lodash_1 = require("lodash");
const merge_configurations_1 = require("../../utils/merge-configurations");
const defaultConfiguration = {};
class EmptyAnnotationManager {
    constructor(scene, camera, configuration = {}) {
        this.annotations = [];
        this.lastResults = [];
        this.forceNextUpdate = false;
        this.update = (onChange, dims) => {
            const results = this.get(dims);
            if (!(0, lodash_1.isEqual)(this.lastResults, results) || this.forceNextUpdate) {
                this.forceNextUpdate = false;
                this.lastResults = results;
                onChange(results);
            }
        };
        this.configuration = (0, merge_configurations_1.mergeConfigurations)(defaultConfiguration, configuration, false);
        this.scene = scene;
        this.camera = camera;
    }
    create(defs = []) {
        this.annotations = [];
        defs.forEach(({ name, viewAngle }) => {
            const obj = this.scene.getObjectByName(name);
            if (undefined === obj) {
                console.log(`ERROR: Object name ${name} not found.`);
                return;
            }
            this.annotations.push(new annotation_1.Annotation(name, obj, this.camera, viewAngle));
        });
    }
    get(dims = { width: 1.0, height: 1.0 }) {
        return this.annotations.map(annotation => {
            annotation.update();
            return {
                name: annotation.configName,
                left: Math.round(annotation.x * dims.width),
                top: Math.round(annotation.y * dims.height),
                visible: annotation.visible,
            };
        });
    }
}
exports.EmptyAnnotationManager = EmptyAnnotationManager;
