import React from 'react';
import classNames from 'classnames';
import './configuration-menu.scss';

interface IProps {
    children: React.ReactNode,
    className?: string,
    [index: string]: any,
}

const ConfigurationMenu: React.FC<IProps> = ({className, children, ...props}) => {

    const classes = classNames(
        className,
        'cl-configuration-wrapper');

    return (
        <div className={classes} {...props}>
            <div className="cl-configuration-menu">
                {children}
            </div>
        </div>
    )
}

export default ConfigurationMenu