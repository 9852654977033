"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rgbeLoad = void 0;
const RGBELoader_1 = require("three/examples/jsm/loaders/RGBELoader");
const cache = new Map();
const rgbeLoad = (path, onProgress = () => { }) => {
    return new Promise((resolve, reject) => {
        const cached = cache.get(path);
        if (cached) {
            console.log('Read file from cache', path);
            resolve(cached);
            return;
        }
        new RGBELoader_1.RGBELoader()
            // .setDataType(THREE.UnsignedByteType)
            .load(path, (texture) => {
            cache.set(path, texture);
            resolve(texture);
        }, onProgress, reject);
    });
};
exports.rgbeLoad = rgbeLoad;
