import './popup-confirm-button.scss'
import * as React from 'react';
import {IconOk} from "../icons/svgs";
import classNames from 'classnames'

interface iProps {
    className?: string,
    onClick: () => void,
    title: string
}

const PopupConfirmButton: React.FC<iProps> = ({className, ...props}) => {

    const classes = classNames(
        "cl-confirm-button",
        className
    )

    return (
        <div className={classes} {...props} title={props.title} onClick={props.onClick}>
            <IconOk {...props} />
        </div>
    );
};

export default PopupConfirmButton;
