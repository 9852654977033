import * as libSrv from '@canvas-logic/configurator-base/lib/services/share-service';
import { getFileServiceRequests } from '../../requests/file-service-requests';
import { getEmbeddedService } from '../embedded-service';
import * as env from 'env-var';
import { ShareService } from './share-service';
import { IConfigurationModel } from '../configuration-service';

export const CURRENT_VERSION = '1';

let shareSrv: ShareService | undefined;
export const getShareService = async () => {
  if (undefined === shareSrv) {
    const canFileStorage = libSrv.createCanFileStorage(
      env.get('REACT_APP_CL__AR_KEY_PROJECT').required().asString(),
      getFileServiceRequests()
    );

    const libShareSrv: libSrv.ShareService<IConfigurationModel> = libSrv.createShareService(
      canFileStorage,
      await getEmbeddedService()
    );

    shareSrv = new ShareService(libShareSrv);
  }

  return shareSrv;
};