import hash from 'object-hash';
import {LightBoxMode} from "../redux/storages/configuration-storage";

interface IValues {
    material: string,
    cardDispenser: boolean,
    lightBox: boolean,
    thermoPrinter: boolean,
    printerShredder: boolean,
    keyboard: boolean,
    scanner: boolean,
    screenPrivacyFilter: boolean,
    colorValue?: string,
    legColorValue?: string,
    double: boolean,
    mount: string,
    lightBoxMode: LightBoxMode,
    lightBoxText: string[],
    lightBoxImage: File|undefined,
}

export const generateArKey: (values: IValues) => string = (values) => {
    const projectId = process.env.REACT_APP_CL__AR_KEY_PROJECT;
    const version = process.env.REACT_APP_CL__AR_KEY_VERSION;

    const key = hash.MD5({
        material: values.material,
        cardDispenser: values.cardDispenser,
        lightBox: values.lightBox,
        thermoPrinter: values.thermoPrinter,
        printerShredder: values.printerShredder,
        keyboard: values.keyboard,
        scanner: values.scanner,
        screenPrivacyFilter: values.screenPrivacyFilter,
        colorValue: values.colorValue,
        legColorValue: values.legColorValue,
        mount: values.mount,
        double: values.double,
        lightBoxMode: values.lightBoxMode,
        lightBoxText: values.lightBoxText,
        lightBoxImage: values.lightBoxImage,
    });

    return `${projectId}_${version}_${key}`;
}




