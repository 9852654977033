"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.yamlLoad = void 0;
// import yaml from 'js-yaml';
const yaml = require('js-yaml');
const cache = new Map();
const yamlLoad = (path) => __awaiter(void 0, void 0, void 0, function* () {
    const cached = cache.get(path);
    if (cached) {
        console.log('Read file from cache', path);
        return cached;
    }
    const resp = yield fetch(path);
    const yamlStr = yield resp.text();
    const res = yaml.load(yamlStr);
    cache.set(path, res);
    return res;
});
exports.yamlLoad = yamlLoad;
