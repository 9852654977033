import {createSelector} from "reselect";
import { getConfiguration, getCurrentColorValue, getCurrentLegColorValue } from '../configuration-storage/selectors';
import {generateArKey} from '../../../services/ar-key-service'
import {getLightBox} from "../configuration-storage/selectors";

export const arKeySelector = createSelector(
    getConfiguration,
    getCurrentColorValue,
    getCurrentLegColorValue,
    getLightBox,
    (configuration, colorValue, legColorValue, lightBox ) => {
        if(configuration !== undefined) {
            const material = configuration.material.name;

            const values = {
                material,
                cardDispenser: configuration.cardDispenser.has,
                lightBox: configuration.lightBox.has,
                thermoPrinter: configuration.thermoPrinter.has,
                printerShredder: configuration.printerShredder.has,
                keyboard: configuration.keyboard.has,
                scanner: configuration.scanner.has,
                screenPrivacyFilter: configuration.screenPrivacyFilter.has,
                colorValue,
                legColorValue,
                mount: configuration.mount.name,
                double: configuration.double.has,
                lightBoxMode: lightBox.mode,
                lightBoxText: lightBox.text,
                lightBoxImage: lightBox.image,
            }

            return generateArKey(values);
        }
    }
)