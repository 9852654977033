import React from 'react';
import classNames from 'classnames';
import './overlay.scss';

interface IProps {
    transparent?: boolean,
    [index: string]: any,
}

const Overlay:React.FC<IProps> = ({transparent = true, className, ...props}) => {
    const classes = classNames(className, 'cl-overlay', transparent && 'cl-overlay-transparent');

    return (
        <div {...props} className={classes}/>
    );
}

export default Overlay