import {useCallback, useEffect, useMemo, useState} from "react";
import {useAppSelector} from "../redux/store";
import {arKeySelector} from "../redux/storages/ar-key-storage/selectors";
import * as requ from "../requests/ar-requests";

export enum ArFileStatus {
    uninitialized,
    unfinished,
    uploading,
    finished,
    requestError
}

export enum ArFileSuffix {
    glb = 'glb',
    usdz = 'usdz',
}

export enum ArPlacement {
    floor = 'floor',
    wall = 'wall',
}

interface ArFileData {
    status: ArFileStatus,
    href?: string,
}

export interface ArFile extends ArFileData {
    onStart: () => void,
}

interface IUseArFileOptions {
    fileSuffix: ArFileSuffix,
    inline: boolean,
    uploadImmediately?: boolean,
    getBlob: () => Promise<Blob>,
    arPlacement?: ArPlacement,
}

type UseArFile = (options: IUseArFileOptions) => ArFile
export const useArFile: UseArFile = (options) => {
    const {
        fileSuffix,
        inline,
        uploadImmediately = true,
        getBlob,
        arPlacement = 'floor'
    } = options;

    const [data, setData] = useState<ArFileData>({
        status: ArFileStatus.uninitialized,
        href: undefined,
    })

    // get ar key
    const arKey = useAppSelector(arKeySelector)

    const modelViewerParams = useMemo(() => ({
        ar_placement: arPlacement
    }), [arPlacement]);

    // upload file
    const onStart = useCallback(async () => {
        if (arKey) {
            setData({
                status: ArFileStatus.uploading,
                href: undefined,
            });
            const blob = await getBlob();
            try {
                await requ.upload(arKey, fileSuffix, blob);
                setData({
                    status: ArFileStatus.finished,
                    href: requ.getModelViewerUrl(arKey, fileSuffix, modelViewerParams),
                });
            } catch (e) {
                setData({
                    status: ArFileStatus.requestError,
                    href: undefined,
                });
            }
        }
    }, [getBlob, fileSuffix, arKey, modelViewerParams]);

    // model file is in cache?
    useEffect(() => {
        (async () => {
            if (arKey) {
                if (inline) {
                    const blob = await getBlob();
                    const href = URL.createObjectURL(blob);
                    setData({
                        status: ArFileStatus.finished,
                        href
                    });
                } else {
                    try {
                 /*       const {data} = await requ.hasFile(arKey, fileSuffix);
                        if(data.hasFile) {
                            setData({
                                status: ArFileStatus.finished,
                                href: requ.getModelViewerUrl(arKey, fileSuffix, modelViewerParams),
                            });
                        }
                        else*/ if(uploadImmediately) {
                            await onStart();
                        }
                        else {
                            setData({
                                status: ArFileStatus.unfinished,
                                href: undefined,
                            });
                        }
                    } catch (e) {
                        console.log('Request fails...')
                        console.log(e)
                        setData({
                            status: ArFileStatus.requestError,
                            href: undefined,
                        });
                    }
                }
            }
        })()
    }, [inline, fileSuffix, arKey, getBlob, onStart, uploadImmediately])

    return {...data, onStart}
}