import './color-picker-popup.scss'
import React from 'react';
import {HexColorPicker} from "react-colorful";
import ColorInput from '../input/color-input';
import PopupContent from './popup-content';

interface iProps {
    onChange: (color: string) => void,
    color: string,
    t: (str: string) => string,
}

const ColorPickerPopup: React.FC<iProps> = ({color, t, onChange}, props) => {

    return (
        <PopupContent title={t('colorPicker.label')}>
            <div className="cl-colorPicker-color">
                <label className="cl-colorPicker-colorLabel">{t('colorPicker.label')}</label>
                <div className="cl-colorPicker-colorInput">
                    <ColorInput
                        color={color}
                        onChange={onChange}
                    />
                </div>
            </div>
            <HexColorPicker
                color={color}
                onChange={onChange}
            />
        </PopupContent>
    );
};

export default ColorPickerPopup;
