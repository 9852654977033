"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrbitTween = void 0;
const tween_js_1 = require("@tweenjs/tween.js");
const THREE = require("three");
const merge_configurations_1 = require("../utils/merge-configurations");
const defaultConfiguration = {
    root: 'root',
    position: { x: 0, y: 0, z: 0 },
    lookAt: { x: 0, y: 0, z: 0 },
    duration: 800,
    distanceSpeedUp: 0,
    enableControlsOnComplete: true,
};
class OrbitTween {
    constructor(camera, scene, configuration = {}) {
        this.camera = camera;
        this.scene = scene;
        this.configuration = (0, merge_configurations_1.mergeConfigurations)(defaultConfiguration, configuration);
    }
    tweenTo() {
        const cnf = this.configuration;
        return new Promise((resolve) => {
            this.camera.controls.enabled = false;
            tween_js_1.default.removeAll();
            const obj = this.scene.getObjectByName(cnf.root);
            if (!obj) {
                console.log(`ERROR: Object ${cnf.root} is not found in scene.`);
                resolve();
                return;
            }
            const lookAtPosition = new THREE.Vector3().copy(this.camera.controls.target);
            let speedUp = cnf.distanceSpeedUp;
            if (speedUp < 0) {
                console.log(`ERROR: configuration value distanceSpeedUp=${speedUp} must be between 0 and 1.`);
                speedUp = 0;
            }
            else if (speedUp > 1) {
                console.log(`ERROR: configuration value distanceSpeedUp=${speedUp} must be between 0 and 1.`);
                speedUp = 0;
            }
            let duration = cnf.duration;
            if (speedUp > 0) {
                const distance = this.distance();
                duration = cnf.duration * (1 - speedUp * (1 - distance));
            }
            new tween_js_1.default.Tween({
                propA: this.camera.camera.position,
                propB: lookAtPosition,
                propC: obj.rotation,
            })
                .to({
                propA: {
                    x: cnf.position.x,
                    y: cnf.position.y,
                    z: cnf.position.z,
                },
                propB: {
                    x: cnf.lookAt.x,
                    y: cnf.lookAt.y,
                    z: cnf.lookAt.z,
                },
                propC: {
                    y: 0,
                },
            }, duration)
                .easing(tween_js_1.default.Easing.Quadratic.InOut)
                .onUpdate(() => {
                this.camera.camera.lookAt(lookAtPosition);
            })
                .onComplete(() => {
                this.camera.camera.lookAt(lookAtPosition);
                obj.rotation.y = 0;
                this.camera.controls.target.copy(lookAtPosition);
                if (cnf.enableControlsOnComplete) {
                    this.camera.controls.enabled = true;
                }
                resolve();
            })
                .start();
        });
    }
    distance() {
        const cnf = this.configuration;
        const lookAtPosition = new THREE.Vector3().copy(this.camera.controls.target);
        const vec1 = new THREE.Vector3();
        const vec2 = new THREE.Vector3();
        vec1.subVectors(new THREE.Vector3(cnf.position.x, cnf.position.y, cnf.position.z), new THREE.Vector3(lookAtPosition.x, lookAtPosition.y, lookAtPosition.z));
        vec2.subVectors(new THREE.Vector3(this.camera.camera.position.x, this.camera.camera.position.y, this.camera.camera.position.z), new THREE.Vector3(cnf.lookAt.x, cnf.lookAt.y, cnf.lookAt.z));
        vec1.normalize();
        vec2.normalize();
        return vec1.angleTo(vec2) / Math.PI;
    }
}
exports.OrbitTween = OrbitTween;
