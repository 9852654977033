import {ITranslations} from './types';

export const translate = (translations: ITranslations | undefined, s: string): string => {

    if(translations && translations.hasOwnProperty(s)) {

        return translations[s];
    }

    return s;
}