import React from 'react';
import classNames from "classnames";
import "./viewer-menu.scss"

interface IProps {
    horizontal: boolean,
    className?:string,
    [index: string]: any,
}

const ViewerMenu: React.FC<IProps> = ({horizontal, children, className}) => {

    const classes = classNames(
        "cl-viewer-menu",
        horizontal ? "cl-viewer-menu-horizontal" : "cl-viewer-menu-vertical",
        className
    );

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

export default ViewerMenu