class ModelMapperResult {
    readonly objects: string[];
    readonly materials: object[];
    readonly colors: object[];

    constructor(objects: string[], materials: object[], colors: object[]) {
        this.objects = objects;
        this.materials = materials;
        this.colors = colors;
    }
}

export interface IModel {
    cardDispenser: boolean,
    lightBox: boolean,
    printerShredder: boolean,
    thermoPrinter: boolean,
    keyboard: boolean,
    scanner: boolean,
    screenPrivacyFilter: boolean,
    double: boolean,
    mount: string,
    material: string,
    legMaterial: string,
    colorValue?: string,
    legColorValue?: string,
}

enum ObjectType {
    key_board = 'key_board',
    scanner = 'scanner',
    card_dispenser = 'card_dispenser',
    printer_thermo = 'printer_thermo',
    printer_shredder = 'printer_shredder',
    light = 'light',
    front_only = 'front_only',
    front_scanner_shredder = 'front_scanner_shredder',
    front_scanner = 'front_scanner',
    front_shredder = 'front_shredder',
    front_scanner_shredder_terminal = 'front_scanner_shredder_terminal',
    front_shredder_terminal = 'front_shredder_terminal',
    front_terminal = 'front_terminal',
    front_scanner_terminal = 'front_scanner_terminal',
    square_leg = 'square_leg',
    round_leg = 'round_leg',
    wall_mount = 'wall_mount',
    scanner_d = 'scanner_d',
    card_dispenser_d = 'card_dispenser_d',
    printer_shredder_d = 'printer_shredder_d',
    front_only_d = 'front_only_d',
    front_scanner_shredder_d = 'front_scanner_shredder_d',
    front_scanner_d = 'front_scanner_d',
    front_shredder_d = 'front_shredder_d',
    front_scanner_shredder_terminal_d = 'front_scanner_shredder_terminal_d',
    front_shredder_terminal_d = 'front_shredder_terminal_d',
    front_terminal_d = 'front_terminal_d',
    front_scanner_terminal_d = 'front_scanner_terminal_d',
    screen = 'screen',
    screen_d = 'screen_d'
}

const matNameMap = new Map<string, string>([
    ['orange', 'm_frontDummy'],
])

export const getObjects = () => {

    return Object.values(ObjectType);
}

export const mapModel = (model: IModel): ModelMapperResult => {
    const objects: string[] = [];
    objects.push(ObjectType.screen);
    if(model.double) {
        objects.push(ObjectType.screen_d)
    }
    if(model.keyboard) {
        objects.push(ObjectType.key_board)
    }
    if(model.scanner) {
        objects.push(ObjectType.scanner)
        if (model.double) {
            objects.push(ObjectType.scanner_d)
        }
    }
    if(model.mount === ObjectType.square_leg) {
        objects.push(ObjectType.square_leg)
    }
    if(model.mount === ObjectType.round_leg) {
        objects.push(ObjectType.round_leg)
    }
    if(model.mount === ObjectType.wall_mount) {
        objects.push(ObjectType.wall_mount)
    }
    if(model.cardDispenser) {
        objects.push(ObjectType.card_dispenser)
        if (model.double) {
            objects.push(ObjectType.card_dispenser_d)
        }
    }
    if(model.thermoPrinter) {
        objects.push(ObjectType.printer_thermo)
    }
    if(model.printerShredder) {
        objects.push(ObjectType.printer_shredder)
        if (model.double) {
            objects.push(ObjectType.printer_shredder_d)
        }
    }
    if(model.lightBox) {
        objects.push(ObjectType.light)
    }
    if(model.scanner && model.printerShredder && model.cardDispenser) {
        objects.push(ObjectType.front_scanner_shredder_terminal)
        if (model.double) {
            objects.push(ObjectType.front_scanner_shredder_terminal_d)
        }
    } else {
        if(model.scanner && model.printerShredder) {
            objects.push(ObjectType.front_scanner_shredder)
            if (model.double) {
                objects.push(ObjectType.front_scanner_shredder_d)
            }
        }
        else if(model.scanner && !model.printerShredder && !model.cardDispenser) {
            objects.push(ObjectType.front_scanner)
            if (model.double) {
                objects.push(ObjectType.front_scanner_d)
            }
        }         else if(model.scanner && !model.printerShredder && model.cardDispenser) {
            objects.push(ObjectType.front_scanner_terminal)
            if (model.double) {
                objects.push(ObjectType.front_scanner_terminal_d)
            }
        }
        else if(!model.scanner && model.printerShredder && !model.cardDispenser) {
            objects.push(ObjectType.front_shredder)
            if (model.double) {
                objects.push(ObjectType.front_shredder_d)
            }
        } else if (model.printerShredder && model.cardDispenser) {
            objects.push(ObjectType.front_shredder_terminal)
            if (model.double) {
                objects.push(ObjectType.front_shredder_terminal_d)
            }
        } else if (!model.printerShredder && model.cardDispenser && !model.scanner) {
            objects.push(ObjectType.front_terminal)
            if (model.double) {
                objects.push(ObjectType.front_terminal_d)
            }
        }
        else {
            objects.push(ObjectType.front_only)
            if (model.double) {
                objects.push(ObjectType.front_only_d)
            }
        }
    }


    const aoMatName = model.keyboard ? 'front_key_ao' : 'front_ao';

    let materials:object[] = [];
    const matName: string|undefined = matNameMap.get(model.material);
    const legMatName: string|undefined = matNameMap.get(model.legMaterial);
    if(undefined === matName) {
        console.log(`ERROR: Could not map material name ${model.material}`);
    }
    if(undefined === legMatName) {
        console.log(`ERROR: Could not map material name ${model.legMaterial}`);
    }
    else {
        materials = [{
            shadingGroupId: 'mat_board_rahmen',
            matName,
            aoMatName
        }, {
            shadingGroupId: 'mat_board_wanne',
            matName,
            aoMatName
        }, {
            shadingGroupId: 'mat_front',
            matName,
            aoMatName
        }, {
            shadingGroupId: 'mat_leg',
            legMatName,
            aoMatName
        },{
            shadingGroupId: 'mat_display',
            matName: model.screenPrivacyFilter ? 'm_spf' : 'm_display'
        }]
    }

    const colors = [{
        shadingGroupId: 'mat_board_rahmen',
        colorValue: model.colorValue,
    }, {
        shadingGroupId: 'mat_board_wanne',
        colorValue: model.colorValue,
    }, {
        shadingGroupId: 'mat_front',
        colorValue: model.colorValue,
    },  {
        shadingGroupId: 'mat_leg',
        colorValue: model.legColorValue,
    },];

    return new ModelMapperResult(objects, materials, colors);
}