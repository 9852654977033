import React, {useEffect} from 'react';
import './App.scss';
import Configurator from "./components/configurator";
import {useAppSelector, useAppDispatch} from './redux/store';
import * as cnfStorage from './redux/storages/configuration-storage'
import * as i18nStorage from './redux/storages/i18n-storage';
import {languageService} from "./services/engine-service";
import * as envSrv from './services/env-service'
import './polyfills';

export interface IProps {}

const App: React.FC<IProps> = () => {
    const dispatch = useAppDispatch();
    const configuration = useAppSelector(cnfStorage.selectors.getConfiguration);
    const translations = useAppSelector(i18nStorage.getTranslations);

    useEffect(() => {
        const locale = envSrv.locale();
        languageService.setLanguage(locale);
        dispatch(i18nStorage.load(locale));
    }, []);

    if (translations === undefined) {

        return null;
    }
    const mutate = (lookupId: string, value: any) => dispatch(cnfStorage.actions.mutateConfiguration(lookupId, value));

    return (
        <Configurator
            configuration={configuration}
            mutate={mutate}
        />
    );
};

export default App
