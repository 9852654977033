import {stripTrailingSlash, responseStatus} from '../services/utils';

enum UrlType {
    hasFile,
    upload,
    download,
    modelViewer
}

const PATH_MAP = {
    [UrlType.hasFile]: 'has-file',
    [UrlType.upload]: 'upload',
    [UrlType.download]: 'download',
    [UrlType.modelViewer]: 'model-viewer',
}

const generateUrl = (urlType: UrlType) => {
    const host = stripTrailingSlash(String(process.env.REACT_APP_CL__CAN_FILE_SERVICE_HOST));
    const path = PATH_MAP[urlType];

    return `${host}/${path}`;
}

const generateFileName = (arKey: string, fileSuffix: string) => `${arKey}.${fileSuffix}`

const generateApiKeyParam = () => 'api_key=' + String(process.env.REACT_APP_CL__CAN_FILE_SERVICE_API_KEY);

const generateParamString = (options: IUrlParams) => {

    return Object.keys(options).map((key: string) => {

        return `${encodeURIComponent(key)}=${encodeURIComponent(options[key])}`
    }).join('&');
}

const apiFetch = (resource: any, init: RequestInit) => {
    const options: RequestInit = {
        mode: 'cors',
        ...init
    }

    return fetch(resource, options);
}

export const getDownloadUrl = (arKey: string, fileSuffix: string) => {

    return generateUrl(UrlType.download) + '/' + generateFileName(arKey, fileSuffix) + '?' + generateApiKeyParam();
}

interface IUrlParams {
    [index: string]: string,
}

export const getModelViewerUrl = (arKey: string, fileSuffix: string, params?: IUrlParams) => {
    let url = generateUrl(UrlType.modelViewer) + '/' + generateFileName(arKey, fileSuffix) + '?' + generateApiKeyParam();
    if(params) {
        url += '&' + generateParamString(params);
    }

    return url;
}

export interface UploadResponse {
    status: 'ok',
}

export const upload: (arKey: string, fileSuffix: string, blob: Blob) => Promise<UploadResponse> = async (arKey: string, fileSuffix: string, blob: Blob) => {
    console.log('upload')
    const url = generateUrl(UrlType.upload) + '/' + generateFileName(arKey, fileSuffix) + '?' + generateApiKeyParam();
    const fd = new FormData();
    fd.append('file', blob);
    const resp = await apiFetch(url, {
        method: 'POST',
        body: fd
    })

    return responseStatus(resp).json();
}

export interface HasFileResponse {
    status: 'ok',
    data: {
        hasFile: boolean
    }
}

export const hasFile: (arKey: string, fileSuffix: string) => Promise<HasFileResponse> = async (arKey: string, fileSuffix: string) => {
    const url = generateUrl(UrlType.hasFile) + '/' + generateFileName(arKey, fileSuffix)+ '?' + generateApiKeyParam();
    const resp = await apiFetch(url, {
        method: 'GET',
    })

    return  responseStatus(resp).json();
}