import {Context, IContextData} from "./context";
import yaml from 'js-yaml';
import * as envSrv from '../env-service'

let context: Context | undefined
export const getContext = async () => {
    if (!context) {
        let env: string | undefined = envSrv.context();
        let data = {};
        if (undefined !== env) {
            try {
                const resp = await fetch('./assets/contexts/' + env + '.yaml');
                if (resp.status === 200) {
                    const yamlStr = await resp.text();
                    data = (yaml.load(yamlStr) ?? {}) as Partial<IContextData>;
                } else {
                    console.log(`ERROR: Could not load yaml file for context "${env}".`);
                    console.log('ERROR: Response status is ' + resp.status);
                }
            } catch (e) {
                console.log(`ERROR: Could not load yaml file for context "${env}".`);
                console.log(e);
            }
        }
        context = new Context(data)
    }

    return context;
}

export {Context}