import './render-control-button.scss';
import * as React from 'react';
import IconButton from "./icon-button";
import classNames from "classnames";

interface iProps {
    children: React.ReactNode,
    title: string,
    onClick: any,
    isSelected:boolean,
    className?: string,
}

const RenderControlButton:React.FC<iProps> = ({children, className, ...props}) => {
    const classes = classNames('cl-render-control', className)

    return (
        <IconButton
            {...props}
            className={classes}
        >
            {children}
        </IconButton>

    )
}


export default RenderControlButton;
