import React, {useCallback, useState} from 'react';
import LightBoxPopup from './light-box-popup';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import * as cnfStorage from '../../redux/storages/configuration-storage';
import {ILightBox, LightBoxMode} from '../../redux/storages/configuration-storage';
import {useTrans} from "../../redux/storages/i18n-storage";
import Popup, {Position} from "./popup";
import {sanitizer} from '../../services/light-box-service';

interface IProps {
    onClose: () => void,
}

const LightBoxPopupContainer: React.FC<IProps> = ({onClose}) => {
    const dispatch = useAppDispatch();
    const [imageLastUploadError, setImageLastUploadError] = useState<boolean>(false);
    const lightBox = useAppSelector(cnfStorage.selectors.getLightBox);
    const t = useTrans();
    const [initialLightBox] = useState<ILightBox>(lightBox);

    const setLightBox = useCallback((lightBox: ILightBox) => {
        dispatch(cnfStorage.actions.setLightBox(
            lightBox,
            () => setImageLastUploadError(true)
        ));
    }, [dispatch])

    const closeHandler = () => {
        setLightBox(initialLightBox);
        onClose();
    }

    const submitHandler = () => {
        if(imageLastUploadError) {
            setLightBox({
                ...lightBox,
                image: undefined,
            });
            setImageLastUploadError(false);
        }
        onClose();
    }

    const onTextChange = (text: string) => {
        setLightBox({
            ...lightBox,
            text: sanitizer.sanitize(text),
        })
    }

    const onSelectMode = (mode: LightBoxMode) => {
        setLightBox({
            ...lightBox,
            mode,
        })
    }

    const onImageRemove = () => {
        setLightBox({
            ...lightBox,
            image: undefined,
        })
    }

    const onImageUpload = (image: File) => {
        setImageLastUploadError(false)
        setLightBox({
            ...lightBox,
            image,
        })
    }

    return (
        <>
            <Popup position={Position.right} fullHeight={false} onSubmit={submitHandler} onClose={closeHandler}>
                <LightBoxPopup
                    mode={lightBox.mode}
                    text={lightBox.text.join("\n")}
                    image={lightBox.image}
                    imageLastUploadError={imageLastUploadError}
                    onTextChange={onTextChange}
                    onSelectMode={onSelectMode}
                    onImageRemove={onImageRemove}
                    onImageUpload={onImageUpload}
                    t={t}
                />
            </Popup>
        </>
    );
}

export default LightBoxPopupContainer;