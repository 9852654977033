"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeConfigurations = void 0;
const lodash_1 = require("lodash");
/**
 * Deep merge of configuration objects: mergeConfiguration(obj1, obj2, ...)
 * Flat merge of configuration objects: mergeConfiguration(obj1, obj2, ..., false)
 */
// @ts-ignore
const mergeConfigurations = (...args) => {
    let deep = true;
    if (args.length > 0 && typeof args[args.length - 1] === 'boolean') {
        deep = args[args.length - 1];
        args = args.slice(0, args.length - 1);
    }
    if (args.length < 1) {
        throw new Error('Missing args. No objects to merge!');
    }
    args = args.map(arg => undefined === arg || null === arg ? {} : arg);
    if (deep) {
        // @ts-ignore
        return (0, lodash_1.merge)({}, ...args);
    }
    let res = {};
    args.forEach((arg) => {
        for (const [key, value] of Object.entries(arg)) {
            // @ts-ignore
            res[key] = value;
        }
    });
    return res;
};
exports.mergeConfigurations = mergeConfigurations;
