"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PerspectiveCamera = void 0;
const THREE = require("three");
const merge_configurations_1 = require("../utils/merge-configurations");
const defaultConfiguration = {
    fov: 30,
    near: 100,
    far: 1000,
    position: {
        x: 0,
        y: 0,
        z: 0
    },
    lookAt: {
        x: 0,
        y: 0,
        z: 0
    },
};
class PerspectiveCamera {
    constructor(dimensions, configuration = {}) {
        const cnf = (0, merge_configurations_1.mergeConfigurations)(defaultConfiguration, configuration);
        this.camera = new THREE.PerspectiveCamera(cnf.fov, dimensions.width / dimensions.height, cnf.near, cnf.far);
        this.camera.position.set(cnf.position.x, cnf.position.y, cnf.position.z);
        this.camera.lookAt(cnf.lookAt.x, cnf.lookAt.y, cnf.lookAt.z);
    }
    resize(dims) {
        this.camera.aspect = dims.width / dims.height;
        this.camera.updateProjectionMatrix();
    }
}
exports.PerspectiveCamera = PerspectiveCamera;
