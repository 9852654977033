"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectFinder = void 0;
class ObjectFinder {
    constructor(scene) {
        this.scene = scene;
        this.cache = new Map();
    }
    getByName(name) {
        let cached = this.cache.get(name);
        if (!cached) {
            cached = this.scene.getObjectByName(name);
            if (!cached) {
                console.log(`ERROR: Object with id ${name} not found.`);
                return;
            }
            this.cache.set(name, cached);
        }
        return cached;
    }
}
exports.ObjectFinder = ObjectFinder;
