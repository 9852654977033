import ThreeViewer from './three-viewer';
import {fileToImage} from '@canvas-logic/three-viewer';
import {IModelConfiguration} from "../../model.schema";
import {IConfiguration} from "./configuration-types";
import {configurationLoad} from "@canvas-logic/three-viewer";
import {merge} from 'lodash'
import {ILightBox, LightBoxMode} from "../../redux/storages/configuration-storage";
import * as mapper from "./model-mapper";

export enum AnnotationConfigName {
    zoomPosDisplay = 'zoomPos_display',
    zoomPosLight = 'zoomPos_light',
}

export enum Tween {
    Home = 'home',
    Zoom01 = 'zoom01',
    Zoom02 = 'zoom02',
}

class ViewerService {
    private threeViewer = new ThreeViewer();

    get viewer() {

        return this.threeViewer;
    }

    async init(options: object, configuration: Partial<IConfiguration> = {}) {
        const config = await configurationLoad([
            'assets/viewer/definitions.yaml'
        ])

        return this.threeViewer.init(options, merge(config, configuration));
    }

    async reinit(options: object, configuration: Partial<IConfiguration> = {}) {

        return this.threeViewer.reinit(options, configuration);
    }

    showConfiguration(configuration: IModelConfiguration, colorValue?: string, legColorValue?: string) {
        const result = mapper.mapModel({
            cardDispenser: configuration.cardDispenser.has,
            lightBox: configuration.lightBox.has,
            printerShredder: configuration.printerShredder.has,
            thermoPrinter: configuration.thermoPrinter.has,
            keyboard: configuration.keyboard.has,
            scanner: configuration.scanner.has,
            screenPrivacyFilter: configuration.screenPrivacyFilter.has,
            mount: configuration.mount.name,
            material: configuration.material.name,
            legMaterial: configuration.legMaterial.name,
            double: configuration.double.has,
            colorValue,
            legColorValue
        });
        this.viewer.show(mapper.getObjects(), result.objects, result.materials);
        this.viewer.colors = result.colors
    }

    async drawLightBox(lightBox: ILightBox, onImageUploadError: () => void = () => {
    }) {
        if (lightBox.mode === LightBoxMode.text) {
            this.viewer.drawLightBoxText(lightBox.text);
        } else if (lightBox.mode === LightBoxMode.image) {
            if (lightBox.image) {
                try {
                    const img: HTMLImageElement = await fileToImage(lightBox.image)
                    await this.viewer.drawLightBoxImage(img)
                } catch (e) {
                    console.log('Image could not be added.', e)
                    onImageUploadError();
                }
            } else {
                this.viewer.clearLightBox();
            }
        }
    }

    tweenTo(configuration: IModelConfiguration, name: Tween) {

        return this.viewer.tweenTo(name);
    }

    tweenRotate(left: boolean): Promise<void> {

        return this.viewer.rotate(left);
    }

    toImage(configuration: IModelConfiguration): Promise<string> {

        return this.viewer.toImage();
    }

    exportGLTF(): Promise<Blob> {

        return this.viewer.exportGLTF();
    }

    exportUSDZ(): Promise<Blob> {

        return this.viewer.exportUSDZ();
    }
}

export const viewerService = new ViewerService();
// @ts-ignore
window.viewer = viewerService.viewer;