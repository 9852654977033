import React from 'react';
import {useTrans} from '../../redux/storages/i18n-storage';
import Popup, {Position} from "./popup";
import ArPopup from "./ar-popup";
import {viewerService} from "../../services/viewer-service";
import {ArFileSuffix, ArPlacement, useArFile} from "../../hooks/use-ar-file";
import ArButton from "../buttons/ar-button";

export interface IProps {
    onClose: () => void,
}

const getGltfBlob = () => viewerService.exportGLTF();

const ArPopupContainer: React.FC<IProps> = (props) => {
    const t = useTrans();
    const arProps = useArFile({
        fileSuffix: ArFileSuffix.glb,
        inline: false,
        getBlob: getGltfBlob,
        arPlacement: ArPlacement.floor,
        uploadImmediately: true,
    });

    return (
        <Popup
            position={Position.full}
            transparentOverlay={false}
            fullHeight={false}
            onClose={props.onClose}
        >
            <ArPopup t={t}>
                <ArButton t={t} {...arProps}/>
            </ArPopup>
        </Popup>
    );
}

export default ArPopupContainer;