import {
    Engine,
    LanguageService,
    engineUtils,
    LookupMutator,
    Option,
    OptionGroup,
    AsyncYamlDatasetService
} from "@canvas-logic/engine";
import {IModelConfiguration, IMaterial, ILightBoxMeta} from "../../model.schema";
import Restorer from "./restorer";
import { getShareService } from '../share-service';

export const languageService = new LanguageService();

export const appEngine = new Engine();

export const initAppEngine = () => {

    return loadYamls()
        .then((datasetService: AsyncYamlDatasetService) => init(datasetService));
};

export const mutateAppEngine = (configuration: IModelConfiguration, lookupId: string, value: any) => {
    const mutator = new LookupMutator(lookupId, value);

    return appEngine.mutate(configuration!, mutator);
}

export const availableMaterials = (configuration: IModelConfiguration): Option<IMaterial>[] => {
    const options = appEngine.propertyValuesByPath(configuration, 'material');

    return engineUtils.flattenOptions(options as (OptionGroup | Option)[]) as Option<IMaterial>[];
}

export const getInitialLightBoxText = (configuration: IModelConfiguration) => {
    const og =  appEngine.propertyValuesByPath(configuration, 'lightBox')
    if(og && og.length > 0) {
        const g = og[0] as OptionGroup
        const m = g.model as ILightBoxMeta

        return languageService.translate( m.initialText)
    }
    console.log('ERROR: Could not get lightBox property')
}

export const currentMaterial = (configuration: IModelConfiguration): Option<IMaterial> | undefined => {
    const options = availableMaterials(configuration);
    const name = appEngine.getLookupValue(configuration, 'model.material');

    for (let i = 0; i < options.length; i++) {
        if (options[i].model.name === name) {

            return options[i];
        }
    }
}

export const parsePaletteValue = (strValue: string): string[] => strValue.split(',')

const loadYamls = async (): Promise<AsyncYamlDatasetService> => {
    try {
        const [schema, data] = await Promise.all([
            fetch('assets/datasets/model.schema.yml').then(r => r.text()),
            Promise.all([
                    fetch(`assets/datasets/model.product_variants.yml`).then(r => r.text()),
                    fetch(`assets/datasets/model.products.yml`).then(r => r.text()),
                    fetch(`assets/datasets/model.product_configurations.yml`).then(r => r.text())
                ]
            )
        ]);

        return AsyncYamlDatasetService.create(schema, data)
    } catch (error) {
        console.log(error);
        throw new Error('Error downloading one or more files:' + error);
    }
};

const init = async (datasetService: AsyncYamlDatasetService) => {
    const schema = datasetService.getProductSchema('', {}, '');
    let productWithContext = datasetService.getProductById('model_configuration_1');
    const shareSrv = await getShareService();
    const model = await shareSrv.fetch();
    if(model) {
        const restorer = new Restorer(datasetService);
        restorer.restore(productWithContext, model.model);
        try {
            return appEngine.initByProductWithContext(schema, productWithContext) as IModelConfiguration;
        }
        catch(e) {
            shareSrv.reload();
            console.log(e);
            throw new Error('ERROR: Could not restore configuration.');
        }
    }

    return appEngine.initByProductWithContext(schema, productWithContext) as IModelConfiguration;
};