export interface IContextData {
    titleLangId: string,
    showPrice: boolean,
    enableMobileScrolling: boolean,
}

const defaultContextData = {
    titleLangId: 'configuration.header.title',
    showPrice: false,
    enableMobileScrolling: false,
}

export class Context {
    readonly data:IContextData;

    constructor(contextData: Partial<IContextData> = {}) {
        this.data = {...defaultContextData, ...contextData}
    }

    get titleLangId() {

        return this.data.titleLangId;
    }

    get showPrice() {

        return this.data.showPrice;
    }

    get enableMobileScrolling() {

        return this.data.enableMobileScrolling;
    }
}