import {reduxName} from './constants'
import type {RootState} from '../../store';
import {IModelConfiguration, IMaterial} from "../../../model.schema";
import {createSelector} from 'reselect'
import {BigNumber, Option} from '@canvas-logic/engine';
import * as engineSrv from '../../../services/engine-service';
import {ILightBox} from "./reducer";
import {totalPrice} from '../../../services/price-service'
import { toModel } from '../../../services/configuration-service';

export const getConfiguration = (state: RootState): IModelConfiguration | undefined => state[reduxName].configuration;
export const getColors = (state: RootState): Map<string, string> => state[reduxName].colors;
export const getLightBox = (state: RootState): ILightBox => state[reduxName].lightBox;

export const getTotalPrice = createSelector(
    getConfiguration,
    (configuration: IModelConfiguration | undefined): BigNumber | undefined => {
        if (configuration) {

            return totalPrice(configuration);
        }
    }
)

export const currentMaterial = createSelector(
    getConfiguration,
    (configuration: IModelConfiguration | undefined): Option<IMaterial> | undefined => {
        if (configuration) {

            return engineSrv.currentMaterial(configuration);
        }
    }
)

export const getCurrentColorValue = createSelector(
    getConfiguration,
    getColors,
    (configuration, colors) => {
        if(configuration) {

            return colors.get(configuration.material.name)
        }
    }
);

export const getCurrentLegColorValue = createSelector(
  getConfiguration,
  getColors,
  (configuration, colors) => {
    if(configuration) {
      return colors.get(configuration.legMaterial.name)
    }
  }
);

export const getConfigurationModel = createSelector(
  getConfiguration,
  getLightBox,
  getCurrentColorValue,
  getCurrentLegColorValue,
  (configuration, lightBox, colorValue, legColorValue) => {
    if(configuration) {

      return toModel(configuration, lightBox, colorValue, legColorValue);
    }
  }
)