"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialUpdater = void 0;
class MaterialUpdater {
    constructor(object) {
        this.object = object;
    }
    updateMaterial(options) {
        // @ts-ignore
        if (!this.object.material) {
            console.log(`ERROR: Object with name ${this.object.name} has no material.`);
            return;
        }
        // @ts-ignore
        if (options.material.type !== this.object.material.type) {
            // @ts-ignore
            console.log(`ERROR: Target material (${options.material.type}) and source material (${this.object.material.type}) should have same type.`);
            return;
        }
        // @ts-ignore
        this.object.material.copy(options.material);
        // @ts-ignore
        if (options.aoMap) {
            // @ts-ignore
            this.object.material.aoMap = options.aoMap;
        }
        // @ts-ignore
        if (this.object.material.aoMap) {
            this.copyToUv2IfNeeded();
        }
        // @ts-ignore
        this.object.material.needsUpdate = true;
    }
    setMaterial(options) {
        // @ts-ignore
        if (!this.object.material) {
            console.log(`ERROR: Object with name ${this.object.name} has no material.`);
            return;
        }
        // @ts-ignore
        this.object.material = options.material;
        // @ts-ignore
        if (options.aoMap) {
            // @ts-ignore
            this.object.material.aoMap = options.aoMap;
        }
        // @ts-ignore
        if (this.object.material.aoMap) {
            this.copyToUv2IfNeeded();
        }
        // @ts-ignore
        this.object.material.needsUpdate = true;
    }
    setColor(color) {
        // @ts-ignore
        if (!this.object.material) {
            console.log(`ERROR: Object with name ${this.object.name} has no material.`);
            return;
        }
        // @ts-ignore
        if (!this.object.material.color) {
            console.log(`ERROR: Object with name ${this.object.name} has no material color.`);
            return;
        }
        // @ts-ignore
        this.object.material.color.setStyle(color);
        // @ts-ignore
        this.object.material.color.convertSRGBToLinear();
    }
    copyToUv2IfNeeded() {
        // @ts-ignore
        if (this.object.geometry.attributes.uv2 === undefined && this.object.geometry.attributes.uv !== undefined) {
            // @ts-ignore
            this.object.geometry.setAttribute('uv2', this.object.geometry.attributes.uv);
            // @ts-ignore
            this.object.geometry.attributes.uv2.needsUpdate = true;
        }
    }
}
exports.MaterialUpdater = MaterialUpdater;
