"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrbitControlsCamera = void 0;
const THREE = require("three");
const OrbitControls_1 = require("three/examples/jsm/controls/OrbitControls");
const perspective_camera_1 = require("./perspective-camera");
const merge_configurations_1 = require("../utils/merge-configurations");
const defaultConfiguration = {
    camera: {
        fov: 30,
        near: 100,
        far: 1000,
        position: {
            x: 0,
            y: 0,
            z: 0
        },
        lookAt: {
            x: 0,
            y: 0,
            z: 0
        },
    },
    controls: {
        enableZoom: true,
        enableDamping: true,
        dampingFactor: 0.1,
        minDistance: 0.1,
        maxDistance: 700,
        enablePan: false,
        minPolarAngle: 20,
        maxPolarAngle: 85,
        autoRotate: false,
        autoRotateSpeed: 4,
        minAzimuthAngle: undefined,
        maxAzimuthAngle: undefined,
    }
};
class OrbitControlsCamera {
    constructor(canvas, dims, configuration = {}) {
        this.configuration = (0, merge_configurations_1.mergeConfigurations)(defaultConfiguration, configuration);
        const cnf = this.configuration;
        this._camera = new perspective_camera_1.PerspectiveCamera(dims, cnf.camera);
        this.controls = new OrbitControls_1.OrbitControls(this.camera, canvas);
        this.controls.enableZoom = cnf.controls.enableZoom;
        this.controls.enableDamping = cnf.controls.enableDamping;
        this.controls.dampingFactor = cnf.controls.dampingFactor;
        this.controls.minDistance = cnf.controls.minDistance;
        this.controls.maxDistance = cnf.controls.maxDistance;
        this.controls.target.copy(new THREE.Vector3(cnf.camera.lookAt.x, cnf.camera.lookAt.y, cnf.camera.lookAt.z));
        this.controls.enablePan = cnf.controls.enablePan;
        this.controls.minPolarAngle = cnf.controls.minPolarAngle * Math.PI / 180;
        this.controls.maxPolarAngle = cnf.controls.maxPolarAngle * Math.PI / 180;
        this.controls.autoRotate = cnf.controls.autoRotate;
        this.controls.autoRotateSpeed = cnf.controls.autoRotateSpeed;
        if (undefined !== cnf.controls.minAzimuthAngle) {
            this.controls.minAzimuthAngle = cnf.controls.minAzimuthAngle * Math.PI / 180;
        }
        if (undefined !== cnf.controls.maxAzimuthAngle) {
            this.controls.maxAzimuthAngle = cnf.controls.maxAzimuthAngle * Math.PI / 180;
        }
        this.controls.update();
        this.controls.saveState();
    }
    get camera() {
        return this._camera.camera;
    }
    update() {
        // only required if controls.enableDamping = true, or if controls.autoRotate = true
        if (this.controls.enabled) {
            this.controls.update();
        }
        this.camera.updateMatrixWorld();
    }
    resize(dims) {
        this._camera.resize(dims);
    }
}
exports.OrbitControlsCamera = OrbitControlsCamera;
