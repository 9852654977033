"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SceneManager = void 0;
const object_finder_1 = require("./object-finder");
const material_updater_1 = require("./material-updater");
const material_finder_1 = require("./material-finder");
class SceneManager {
    constructor(scene, materialLibrary) {
        this.materialLibrary = materialLibrary;
        this.objectFinder = new object_finder_1.ObjectFinder(scene);
        this.materialFinder = new material_finder_1.MaterialFinder(scene);
    }
    setVisible(names, visible) {
        names.forEach((name) => {
            const obj = this.objectFinder.getByName(name);
            if (obj) {
                obj.visible = visible;
            }
        });
    }
    updateMaterial(matName, options, doCopy = true) {
        const objects = this.materialFinder.getByName(matName);
        if (!objects) {
            return;
        }
        const mat = this.materialLibrary.get(options.matName);
        if (!mat) {
            console.log(`ERROR: Material with id ${options.matName} not found.`);
            return;
        }
        let aoMap = options.aoMatName;
        if (options.aoMatName) {
            aoMap = this.getAoMap(options.aoMatName);
        }
        objects.forEach(obj => {
            const updater = new material_updater_1.MaterialUpdater(obj);
            if (doCopy) {
                updater.updateMaterial({ material: mat, aoMap });
            }
            else {
                updater.setMaterial({ material: mat, aoMap });
            }
        });
    }
    setColor(matName, color) {
        const objects = this.materialFinder.getByName(matName);
        if (!objects) {
            return;
        }
        objects.forEach(obj => {
            if (obj) {
                (new material_updater_1.MaterialUpdater(obj)).setColor(color);
            }
        });
    }
    getAoMap(aoMatName) {
        let aoMap;
        const aoMat = this.materialLibrary.get(aoMatName);
        if (!aoMat) {
            console.log(`ERROR: Material with id ${aoMatName} not found.`);
            return;
        }
        // @ts-ignore
        aoMap = aoMat === null || aoMat === void 0 ? void 0 : aoMat.aoMap;
        if (!aoMap) {
            console.log(`ERROR: Material with id ${aoMatName} has no aoMap.`);
            return;
        }
        return aoMap;
    }
}
exports.SceneManager = SceneManager;
