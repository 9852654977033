import React from 'react';
import {appEngine, languageService} from '../../services/engine-service';
import {IModelConfiguration} from "../../model.schema";
import IconTextButton from "../buttons/icon-text-button";
import {engineUtils, Option, OptionGroup} from "@canvas-logic/engine";
import Switcher from "../switcher";
import {getIcon} from "../icons/svgs";
import { disabled } from '../input/image-upload-input.stories';

interface IProps {
    configuration?: IModelConfiguration,
    lookupId: string,
    propertyPath: string,
    mutate: any,
    disabled?: boolean,
    disabledOpt?: string
}

const InputLookup: React.FC<IProps> = ({configuration, lookupId, propertyPath, mutate, disabled, disabledOpt, ...props}) => {
    if (configuration === undefined) {

        return null;
    }
    const options = appEngine.propertyValuesByPath(configuration, propertyPath);
    const flatOptions = engineUtils.flattenOptions(options as (OptionGroup | Option)[]) as Option<any>[];
    const current = appEngine.getLookupValue(configuration, lookupId);

    return (
        <Switcher>
            {flatOptions.map(({model}) => {

                let IconNode = getIcon(model.icon)

                return (
                    <IconTextButton
                        {...props}
                        key={model.name}
                        label={languageService.translate(model.title)}
                        isSelected={current === model.name}
                        disabled={current === model.name || (disabled && disabledOpt === model.name)}
                        onClick={() => {
                            mutate(lookupId, model.name)
                        }}
                    >
                        <IconNode/>
                    </IconTextButton>
                )
            })}
        </Switcher>
    )
};

export default InputLookup