import React, {useEffect, useRef, useState} from 'react';
import {IconClose, IconUpload} from "../icons/svgs";
import classNames from 'classnames'
import './image-upload-input.scss'

interface IProps {
    t: (str: string) => string,
    disabled?: boolean,
    lastUploadFile?: File,
    lastUploadError?: boolean,
    selectDisabled?: boolean
    onSelect?: (file: File) => void,
    onUpload: (file: File) => void,
    onRemove: (file: File) => void,
    [index: string]: any,
}

let counter = 0;

const ImageUploadInput: React.FC<IProps> = ({t, disabled=false, lastUploadFile, lastUploadError = false, selectDisabled= false, className, onSelect, onUpload, onRemove, ...props}) => {
    const dropRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [over, setOver] = useState<boolean>(false);
    const [url, setUrl] = useState<string | ArrayBuffer | null>(null);

    const changeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (null !== ev.target.files && ev.target.files.length > 0) {
            onUpload(ev.target.files[0]);
        }
    }

    useEffect(() => {
        if (lastUploadFile) {
            const reader = new FileReader();
            reader.onload = function () {
                setUrl(reader.result);
            }
            reader.readAsDataURL(lastUploadFile);
        }
    }, [lastUploadFile])

    useEffect(() => {
        if (dropRef && dropRef.current !== null && inputRef && inputRef.current !== null) {
            const dropZoneElement: HTMLDivElement = dropRef.current;
            const inputElement: HTMLInputElement = inputRef.current;

            const clickHandler = () => {
                inputElement.click();
            }

            const dragEnterHandler = (ev: Event) => {
                ev.preventDefault();
                counter++;
                setOver(true);
            }

            const dragOverHandler = (ev: Event) => {
                ev.preventDefault();
            }

            const dragLeaveHandler = (ev: Event) => {
                counter--;
                if (0 === counter) {
                    setOver(false);
                }
            }

            const dragEndHandler = (ev: Event) => {
                ev.preventDefault();
                counter = 0;
                setOver(false);
            }

            const dropHandler = (ev: any) => {
                ev.preventDefault();
                counter = 0;
                if (ev.dataTransfer && ev.dataTransfer.files.length > 0) {
                    onUpload(ev.dataTransfer.files[0]);
                }
                setOver(false);
            }

            dropZoneElement.addEventListener('click', clickHandler);
            dropZoneElement.addEventListener('dragenter', dragEnterHandler);
            dropZoneElement.addEventListener('dragover', dragOverHandler);
            dropZoneElement.addEventListener('dragleave', dragLeaveHandler);
            dropZoneElement.addEventListener('dragend', dragEndHandler);
            dropZoneElement.addEventListener('drop', dropHandler);

            return () => {
                dropZoneElement.removeEventListener('click', clickHandler)
                dropZoneElement.removeEventListener('dragenter', dragEnterHandler);
                dropZoneElement.removeEventListener('dragover', dragOverHandler);
                dropZoneElement.removeEventListener('dragleave', dragLeaveHandler);
                dropZoneElement.removeEventListener('dragend', dragEndHandler);
                dropZoneElement.removeEventListener('drop', dropHandler);
            }
        }
    }, [onUpload]);

    const classes = classNames(
        'cl-imageUpload',
        over && 'cl-imageUpload-over',
        lastUploadFile && !lastUploadError && 'cl-imageUpload-success',
        className
    );

    return (
        <div {...props} className={classes}>
            <div ref={dropRef} className="cl-imageUpload-drop">
                {
                    lastUploadFile && !lastUploadError && <ImageContent url={url}/>
                }
                {
                    !lastUploadFile && !lastUploadError && <DefaultContent t={t}/>
                }
                {
                    lastUploadError && <ErrorContent t={t}/>
                }
            </div>
            {
                lastUploadFile && !lastUploadError && (
                    <FileNameContent
                        t={t}
                        file={lastUploadFile}
                        onRemove={onRemove}
                        onSelect={onSelect}
                        selectDisabled={selectDisabled}
                    />
                )
            }
            <input
                onChange={changeHandler}
                style={styles.input}
                ref={inputRef}
                type="file"
            />
            {disabled && <div className="cl-imageUpload-disabled"/>}
        </div>
    );
}

interface IDefaultContentProps {
    t: (str: string) => string,
}

const DefaultContent: React.FC<IDefaultContentProps> = ({t}) => {

    return (
        <div className="cl-imageUpload-text">
            <IconUpload/>
            <div className="cl-imageUpload-title">{t("imageUpload.title")}</div>
            <div className="cl-imageUpload-subTitle">{t("imageUpload.subTitle")}</div>
            <div className="cl-imageUpload-desc">{t("imageUpload.desc")}</div>
        </div>
    )
}

interface IImageContentProps {
    url: string | ArrayBuffer | null
}

const ImageContent: React.FC<IImageContentProps> = ({url}) => {
    const imageStyles = {
        backgroundImage: url ? `url('${url}')` : 'none',
    }

    return (
        <div
            style={imageStyles}
            className="cl-imageUpload-thumb"
        />
    )
}

interface IFileNameContentProps {
    file: File,
    selectDisabled: boolean,
    onSelect?: (file: File) => void,
    onRemove: (file: File) => void,
    t: (str: string) => string
}

const FileNameContent: React.FC<IFileNameContentProps> = ({file, selectDisabled, onSelect, onRemove, t}) => {
    let selectHandler = () => {};
    if(!selectDisabled && onSelect) {
        selectHandler = () => {
            onSelect(file)
        };
    }
    const removeHandler = () => {
        onRemove(file);
    }

    return (
        <div className="cl-imageUpload-fileName">
            <span
                className={classNames('cl-imageUpload-fileNameName', selectDisabled && 'cl-imageUpload-fileNameNameDisabled')}
                onClick={selectHandler}
            >
                {file.name}
            </span>
            <IconClose
                title={t("remove.custom.image")}
                className="cl-imageUpload-fileNameClose"
                onClick={removeHandler}
            />
        </div>
    )
}

interface IErrorContentProps {
    t: (str: string) => string,
}

const ErrorContent: React.FC<IErrorContentProps> = ({t}) => {

    return (
        <div className="cl-imageUpload-text">
            <div className="cl-imageUpload-error">{t("imageUpload.error")}</div>
            <div className="cl-imageUpload-desc">{t("imageUpload.desc")}</div>
        </div>
    )
}

const styles =
    {
        input: {
            display: 'none',
        }
    }

export default ImageUploadInput