import React from 'react';
import PopupContent from "./popup-content";
import './ar-popup.scss';

export interface IProps {
    t: (key: string) => string,
}

const ArPopup: React.FC<IProps> = ({t, children,}) => {

    return (
        <PopupContent title={t('ar.title')} className="cl-arPopup">
            <div className="cl-arPopup-container">
              <div className="cl-arPopup-server-info">{t('ar.server.exchange.info.text')}</div>
                {children}
                <div className="cl-arPopup-server-hint" dangerouslySetInnerHTML={{__html: t("ar.server.exchange.hint.text")}}/>
            </div>
        </PopupContent>
    );
}

export default ArPopup