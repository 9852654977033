import * as t from './action-types';
import type {AnyAction} from 'redux'
import {AnnotationConfigName} from "../../../services/viewer-service";

export interface IState {
    zooming: boolean,
    selectedZoom: AnnotationConfigName | undefined,
    rotatingLeft: boolean,
    rotatingRight: boolean,
}

const initialState: IState = {
    zooming: false,
    selectedZoom: undefined,
    rotatingLeft: false,
    rotatingRight: false,
};

const reducer = function (state: IState = initialState, action: AnyAction): IState {
    switch (action.type) {
        case t.START_ZOOM:

            return {
                ...state,
                selectedZoom: action.selectedZoom,
                zooming: true,
            };
        case t.FINISH_ZOOM:

            return {
                ...state,
                zooming: false,
            };
        case t.START_ROTATING:

            return {
                ...state,
                rotatingLeft: action.left,
                rotatingRight: !action.left,
            };
        case t.FINISH_ROTATING:

            return {
                ...state,
                rotatingLeft: false,
                rotatingRight: false,
            };
        case t.SET_ROTATION_ANGLE:

            return {
                ...state,
            };
    }

    return state
}

export default reducer;