"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialTempUpdate = void 0;
const material_updater_1 = require("./material-updater");
class MaterialTempUpdate {
    constructor(sceneManager) {
        this.sceneManager = sceneManager;
    }
    set(matName, options) {
        this.objects = this.sceneManager.materialFinder.getByName(matName);
        this.oldMats = this.objects.map((obj) => obj.material);
        this.sceneManager.updateMaterial(matName, options, false);
    }
    restore() {
        if (!this.objects || !this.oldMats) {
            throw new Error("Can't call restore before set method.");
        }
        this.objects.forEach((obj, i) => {
            // @ts-ignore
            (new material_updater_1.MaterialUpdater(obj)).setMaterial({ material: this.oldMats[i] });
        });
        this.objects = undefined;
        this.oldMats = undefined;
    }
}
exports.MaterialTempUpdate = MaterialTempUpdate;
