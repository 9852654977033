"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YoyoTween = void 0;
const tween_js_1 = require("@tweenjs/tween.js");
const merge_configurations_1 = require("../utils/merge-configurations");
const defaultConfiguration = {
    root: 'root',
    minAngle: -50,
    maxAngle: 50,
    duration: 8000,
};
class YoyoTween {
    constructor(scene, configuration = {}) {
        this.left = true;
        this.scene = scene;
        this.configuration = (0, merge_configurations_1.mergeConfigurations)(defaultConfiguration, configuration);
    }
    tweenTo() {
        const cnf = this.configuration;
        const root = this.scene.getObjectByName(cnf.root);
        if (root) {
            root.rotation.y = cnf.minAngle * Math.PI / 180;
            new tween_js_1.default.Tween(root.rotation)
                .yoyo(true)
                .to({ y: cnf.maxAngle * Math.PI / 180 }, cnf.duration)
                .easing(tween_js_1.default.Easing.Quadratic.InOut)
                .repeat(Infinity)
                .start();
        }
    }
}
exports.YoyoTween = YoyoTween;
