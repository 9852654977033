import React, {useRef, useState, useMemo} from 'react';
import './app-layout.scss';
import classNames from "classnames";
import {useTrans} from "../redux/storages/i18n-storage";
import Overlay from "./overlay";
import CloseButton from "./buttons/close-button";
import GrabbingSpace from "./grabbing-space";
import GrabbingOverlay from "./grabbing-overlay";
import {useEscKeyDown} from "../hooks/use-esc-key-down";
import {useSize} from "../hooks/use-size";
import {isMobileDevice} from "../services/utils";

interface IProps {
    renderConfiguration?: any,
    renderPopups?: any,
    renderViewport?: any,
    disabledConfiguration?: boolean,
    fullSize?: boolean,
    onFullSizeClose?: () => void,
    fullSizeCloseDisabled?: boolean,
    enableMobileScrolling?: boolean,
}

const AppLayout: React.FC<IProps> = ({
                                         fullSize = false,
                                         onFullSizeClose = () => {},
                                         fullSizeCloseDisabled = true,
                                         renderConfiguration,
                                         renderPopups,
                                         renderViewport,
                                         disabledConfiguration = false,
                                         enableMobileScrolling= false
                                     }
) => {

    const appLayoutClasses = classNames(
        "cl-app-layout",
        fullSize ? "cl-app-fullsize" : "cl-app-devided"
    );
    const t = useTrans();
    const ref = useRef<HTMLDivElement>(null);
    const dimensions = useSize(ref?.current)
    useEscKeyDown(onFullSizeClose, !fullSizeCloseDisabled);
    const [isBlocked, setIsBlocked] = useState<boolean>(true)
    const isMobile = useMemo(isMobileDevice, [])

    return (
        <div className="cl-app">
            <div ref={ref} className={appLayoutClasses}>
                {renderPopups({dimensions})}
                <div className="cl-app-viewportViewer">
                    {enableMobileScrolling && isMobile && isBlocked && <GrabbingOverlay onClose={() => {
                        setIsBlocked(false)
                    }}/>}
                    {
                        fullSize && !fullSizeCloseDisabled ? <CloseButton
                            onClose={onFullSizeClose}
                            title={t("back.button.text")}
                        /> : null
                    }
                    {renderViewport()}
                    {isMobile && !isBlocked &&
                        <GrabbingSpace className={"cl-app-grabbing-space"}/>}
                </div>
                <div className="cl-app-configuration">
                    <div className="cl-app-configuration-container">
                        {((enableMobileScrolling && isMobile && isBlocked) || disabledConfiguration) && <Overlay transparent={false}/>}
                        {renderConfiguration()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppLayout