import {useEffect, useState} from "react";
import {getContext, Context} from "../services/context-service";

export const useAppContext = () => {
    const [context, setContext] = useState<Context|undefined>();

    useEffect(() => {
        (async () => {
            setContext(await getContext());
        })()
    }, [])

    return context;
}