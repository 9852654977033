import React, {useEffect, useState} from 'react';
import {isHexString} from '../../services/utils';
import TextInput from './text-input';

interface IProps {
    className?: string,
    color: string,
    style?: object,
    onChange: (color: string) => void,
    [index: string]: any,
}

const ColorInput: React.FC<IProps> = ({className, style = {}, color, onChange, ...props}) => {
    const [value, setValue] = useState<string>('');
    useEffect(() => {
        setValue((color));
    }, [color]);

    const changeHandler = (ev: React.FormEvent<HTMLInputElement>) => {
        if (ev.currentTarget) {
            let val = ev.currentTarget.value;
            setValue(val);
            val = val.trim();
            if (val !== color && isHexString(val)) {
                onChange(val);
            }
        }
    }

    const styles = {
        ...style,
        width: '5em',
    }

    return (
        <TextInput
            {...props}
            style={styles}
            spellCheck={false}
            value={value}
            onChange={changeHandler}
        />
    );
}

export default ColorInput;