"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmbeddedService = void 0;
class EmbeddedService {
    constructor(postSrv) {
        this.postSrv = postSrv;
        this.initialized = false;
    }
    initialize() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.embedded) {
                return;
            }
            return new Promise(resolve => {
                const handler = (e) => {
                    switch (e.data.type) {
                        case 'initialize':
                            const msg = e.data;
                            this.parentLocation = JSON.parse(msg.location);
                            window.removeEventListener('message', handler);
                            this.initialized = true;
                            resolve();
                    }
                };
                window.addEventListener('message', handler);
                this.sendStartInitialize();
            });
        });
    }
    get embedded() {
        return this.postSrv.embedded;
    }
    writeClipboardText(text) {
        const msg = {
            type: 'writeClipboardText',
            payload: { text }
        };
        this.postSrv.post(msg);
    }
    redirect(url) {
        const msg = {
            type: 'redirect',
            payload: { url }
        };
        this.postSrv.post(msg);
    }
    sendStartInitialize() {
        if (this.initialized) {
            return;
        }
        console.log('embedded service: start init');
        this.postSrv.post({
            type: 'startInitialize'
        });
        setTimeout(() => this.sendStartInitialize(), 200);
    }
}
exports.EmbeddedService = EmbeddedService;
