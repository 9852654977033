"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageStretchCanvasRenderer = void 0;
const merge_configurations_1 = require("../utils/merge-configurations");
const defaultConfiguration = {
    globalCompositeOperation: 'source-over',
    x: 0,
    y: 0,
    width: 1,
    height: 1,
};
class ImageStretchCanvasRenderer {
    constructor(context, configuration = {}) {
        this.context = context;
        this.configuration = (0, merge_configurations_1.mergeConfigurations)(defaultConfiguration, configuration);
    }
    render(image) {
        const width = this.context.canvas.width;
        const height = this.context.canvas.height;
        // @ts-ignore
        this.context.globalCompositeOperation = this.configuration.globalCompositeOperation;
        this.context.drawImage(image, width * this.configuration.x, height * this.configuration.y, width * this.configuration.width, height * this.configuration.height);
    }
}
exports.ImageStretchCanvasRenderer = ImageStretchCanvasRenderer;
