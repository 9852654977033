import React from 'react';
import {ArFile, ArFileStatus} from "../../hooks/use-ar-file";
import LoadingAnimation from "../loading/LoadingCircle";
import "./ar-button.scss"
import classNames from 'classnames'
import ARLink from "../links/ar-link";

interface IProps extends ArFile {
    className?: string,
    t: (key: string) => string,
    [index: string]: any,
}

const ArButton: React.FC<IProps> = ({
                                        className,
                                        status,
                                        href,
                                        t,
                                        onStart,
                                        children,
                                        ...props
                                    }) => {
    const classes = classNames(
        "cl-arButton-container",
        className
    )

    return (
        <div className={classes} {...props}>
            <ARLink href={ArFileStatus.finished === status ? href : undefined}/>
            {ArFileStatus.unfinished === status && (
                <div
                    className="cl-arButton-text cl-arButton-button"
                    onClick={onStart}
                >
                    {t("ar.generate")}
                </div>
            )}
            {[ArFileStatus.uninitialized, ArFileStatus.uploading].includes(status) && (
                <LoadingAnimation/>
            )}
            {ArFileStatus.requestError === status && (
                <div className="cl-arButton-text cl-arButton-error">
                    {t("ar.requestError")}
                </div>
            )}
        </div>
    );
}

export default ArButton