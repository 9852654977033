import {IModelConfiguration} from "../model.schema";
import {BigNumber} from "@canvas-logic/engine";

export const formatPrice = (price:number, locale ='de-DE', currency='EUR') => {

    return new Intl.NumberFormat(locale, {style: 'currency', currency}).format(price)
}

export const totalPrice = (configuration: IModelConfiguration) => {
    let total = new BigNumber(0.0);

    for(const [name, model] of Object.entries(configuration)) {
        console.log(name, model)
        if(model.hasOwnProperty('price')) {
            total.plus(model.price)
        }
    }

    return total;
}
