import React from 'react';
import './rotate-button.scss';
import classnames from 'classnames'
import {IconRotateLeft, IconRotateRight} from '../icons/svgs'
import IconButton from "./icon-button";

interface IProps {
    left: boolean,
    className?: string,
    disabled?: boolean,
    onClick: () => void,
    [index: string]: any
}

const noop = () => {
}

const RotateButton: React.FC<IProps> = ({left, className, disabled = false, title, style={}, onClick, ...props}) => {
    const classes = classnames('cl-rotateButton', left ? 'cl-rotateButton-left' : 'cl-rotateButton-right', disabled ? "cl-rotate-disabled" : "", className);

    const clickHandler = disabled ? noop : onClick;
    const Icon = left ? IconRotateLeft : IconRotateRight;

    return (
            <IconButton
                onClick={clickHandler}
                isSelected={false}
                className={classes}
                title={title}
                {...props}
            >
                <Icon/>
            </IconButton>
    )
}

export default RotateButton