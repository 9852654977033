import React from 'react';
import classNames from 'classnames';
import './text-input-text-area-input.scss';

interface IProps {
    className?: string,
    [index: string]: any,
}

const TextInput = React.forwardRef<HTMLTextAreaElement, IProps>(({className, children, ...props}, ref) => {
    const classes = classNames('cl-textInputTextAreaInput', className);

    return (
        <textarea
            {...props}
            ref={ref}
            className={classes}
        >
            {children}
        </textarea>
    );
})

export default TextInput;