import React, {useEffect, useRef} from 'react';
import LoadingAnimation from '../loading/LoadingCircle'
import './high-res-popup.scss'
import TextButton, {ButtonSize} from "../buttons/text-button";

const GRID_GAP = 16
const BUTTON_HEIGHT = 56 // height of scss variable $large-button-size

const MARGIN = 40

export interface IProps {
    onClose: () => void,
    src: string | undefined,
    t: (key: string) => string,
    dimensions: IDimensions,
    imgDimensions?: IDimensions
}

export interface IDimensions {
    height: number,
    width: number,
}

const HighResPopup: React.FC<IProps> = ({onClose, src, t, dimensions, imgDimensions = {width: 1920, height: 1080}}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [scaledDimensions, setScaledDimensions] = React.useState<IDimensions>();

    useEffect(() => {
        const handleResize = () => {
            const imgAspectRatio = imgDimensions.width / imgDimensions.height // ~9:16
            const availableHeight = dimensions.height - 2 * MARGIN - GRID_GAP - BUTTON_HEIGHT;
            const availableWidth = dimensions.width - 2 * MARGIN;
            const isPortrait = (availableWidth / availableHeight <= imgAspectRatio)

            const dims = {
                height: isPortrait ? availableWidth / imgAspectRatio : availableHeight,
                width: isPortrait ? availableWidth : (availableHeight) * imgAspectRatio,
            };
            setScaledDimensions(dims)
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize)
    }, [dimensions]);

    if (scaledDimensions === undefined) {

        return null
    }

    const cntStyles = {
        display: 'grid',
        gridTemplateRows: "1fr " + BUTTON_HEIGHT + 'px',
        gridGap: GRID_GAP + 'px'
    }

    const loadingStyles = {
        opacity: !!src ? 0 : 1,
        width: scaledDimensions.width + 'px',
        height: scaledDimensions.height + 'px',
    }

    const imgWrapperStyles = {
        width: scaledDimensions.width + 'px',
        height: scaledDimensions.height + 'px',
    }

    return (
        <div ref={ref} className="cl-highres">
            <div style={cntStyles}>

                <div style={imgWrapperStyles} className="cl-highres-imageWrapper">
                    {src && (
                        <img id="cl_highres_image" className="cl-highres-image" src={src}
                             alt=""/>
                    )}
                </div>
                {src && (
                    <div style={{height: BUTTON_HEIGHT + 'px', overflow: 'hidden'}} >
                        <TextButton onClick={onClose} as={'a'} href={src} download={t('high_res_file_name')} centered={true} className="cl-highres-button" size={ButtonSize.large}>
                            {t("save.image")}
                        </TextButton>
                    </div>
                )}
                <div style={loadingStyles} className="cl-highres-loading">
                    <LoadingAnimation/>
                </div>
            </div>
        </div>
    );
}

export default HighResPopup