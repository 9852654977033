import {getUrlParam} from "../helpers";

const LOCALES = ['de', 'en'];
const DEFAULT_LOCALE = 'en';

export const loadLocale = () => {
    let locale = getUrlParam('locale');
    if (null === locale) {
        locale = process.env.REACT_APP_CL__LOCALE ?? null;
    }
    if (null === locale) {

        return DEFAULT_LOCALE;
    }
    locale = locale.trim().toLowerCase();
    if (!LOCALES.includes(locale)) {
        console.log(`ERROR: value ${locale} for url parameter locale is invalid. Valid locales are: ${LOCALES.join(', ')}.`);

        return DEFAULT_LOCALE;
    }

    return locale;
}

let cached: string | undefined = undefined;
export const locale = () => {
    if (!cached) {
        cached = loadLocale()
    }

    return cached;
}