"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RotateTween = void 0;
const tween_js_1 = require("@tweenjs/tween.js");
const merge_configurations_1 = require("../utils/merge-configurations");
const defaultConfiguration = {
    root: 'root',
    stepSize: 45,
    duration: 600,
};
class RotateTween {
    constructor(scene, configuration = {}) {
        this.left = true;
        this.scene = scene;
        this.configuration = (0, merge_configurations_1.mergeConfigurations)(defaultConfiguration, configuration);
    }
    tweenTo() {
        const cnf = this.configuration;
        return new Promise((resolve) => {
            if (tween_js_1.default.getAll().length === 0) {
                const relDir = this.left ? '-' : '+';
                const rotStep = cnf.stepSize * Math.PI / 180;
                const obj = this.scene.getObjectByName(cnf.root);
                if (!obj) {
                    console.log(`ERROR: Object ${cnf.root} is not found in scene.`);
                    resolve();
                    return;
                }
                new tween_js_1.default.Tween(obj.rotation)
                    .to({ y: relDir + rotStep }, cnf.duration)
                    .easing(tween_js_1.default.Easing.Quadratic.InOut)
                    .onComplete(() => {
                    // Check that the full 360 degrees of rotation,
                    // and calculate the remainder of the division to avoid overflow.
                    if (Math.abs(obj.rotation.y) >= 2 * Math.PI) {
                        obj.rotation.y = obj.rotation.y % (2 * Math.PI);
                    }
                    if (obj.rotation.y < -Math.PI) {
                        obj.rotation.y += 2 * Math.PI;
                    }
                    else if (obj.rotation.y > Math.PI) {
                        obj.rotation.y -= 2 * Math.PI;
                    }
                    resolve();
                })
                    .start();
            }
        });
    }
}
exports.RotateTween = RotateTween;
