import {IModelConfiguration} from "../../model.schema";
import { ILightBox } from '../../redux/storages/configuration-storage';

export interface IConfigurationModel {
    readonly cardDispenser: boolean,
    readonly printerShredder: boolean,
    readonly thermoPrinter: boolean,
    readonly keyboard: boolean,
    readonly scanner: boolean,
    readonly screenPrivacyFilter: boolean,
    readonly material: string,
    readonly colorValue?: string,
    readonly lightBox?: ILightBox,
    readonly legColorValue?: string,
    readonly double: boolean,
    readonly mount: string,
}


export const toModel = (configuration: IModelConfiguration, lightBox: ILightBox, colorValue?: string, legColorValue?: string): IConfigurationModel => {

    return {
        cardDispenser: configuration.cardDispenser.has,
        printerShredder: configuration.printerShredder.has,
        thermoPrinter: configuration.thermoPrinter.has,
        keyboard: configuration.keyboard.has,
        scanner: configuration.scanner.has,
        screenPrivacyFilter: configuration.screenPrivacyFilter.has,
        material: configuration.material.name,
        colorValue,
        legColorValue,
        mount: configuration.mount.name,
        double: configuration.double.has,
        lightBox: configuration.lightBox.has ? lightBox : undefined,
    }
}