export const setCaretPosition = (elem: any, caretPos: number) => {
    if (elem.createTextRange) {
        var range = elem.createTextRange();
        range.move('character', caretPos);
        range.select();
    } else {
        if (elem.selectionStart !== undefined) {
            elem.focus();
            elem.setSelectionRange(caretPos, caretPos);
        } else
            elem.focus();
    }
}