"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fileToImage = void 0;
const fileToImage = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
        const url = reader.result;
        const img = new Image();
        img.onload = () => {
            resolve(img);
        };
        img.src = url;
        img.onerror = reject;
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
});
exports.fileToImage = fileToImage;
