import './popup-close-button.scss'
import * as React from 'react';
import {IconClose} from "../icons/svgs";
import classNames from 'classnames'

interface iProps {
    className?: string,
    title: string,
    onClose: () => void,
}

const PopupCloseButton: React.FC<iProps> = ({title, className, ...props}) => {

    const classes = classNames(
        "cl-viewport-close",
        className
    )
    return (
        <div className={classes} {...props} title={title} onClick={props.onClose}><IconClose {...props} /></div>
    );
};

export default PopupCloseButton;
