"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebglRenderer = exports.defaultConfiguration = void 0;
const THREE = require("three");
const merge_configurations_1 = require("../utils/merge-configurations");
exports.defaultConfiguration = {
    alpha: true,
    premultipliedAlpha: true,
    antialias: true,
    preserveDrawingBuffer: false,
    logarithmicDepthBuffer: true,
    debugCheckShaderErrors: true,
};
class WebglRenderer {
    constructor(canvas, configuration = {}) {
        this.configuration = (0, merge_configurations_1.mergeConfigurations)(exports.defaultConfiguration, configuration);
        const params = {
            canvas,
            alpha: this.configuration.alpha,
            premultipliedAlpha: this.configuration.premultipliedAlpha,
            antialias: this.configuration.antialias,
            preserveDrawingBuffer: this.configuration.preserveDrawingBuffer,
            logarithmicDepthBuffer: this.configuration.logarithmicDepthBuffer,
        };
        this.renderer = new THREE.WebGLRenderer(params);
        this.renderer.debug.checkShaderErrors = this.configuration.debugCheckShaderErrors; // It may be useful to disable this check in production for
        // performance gain
        // this.renderer.gammaFactor = 2; // default is 2
        this.renderer.outputEncoding = THREE.sRGBEncoding; // Default is THREE.LinearEncoding.
        this.renderer.physicallyCorrectLights = true; // Default is false
        this.renderer.toneMapping = THREE.NoToneMapping; // Default is NoToneMapping
        this.renderer.toneMappingExposure = 1; // Default is 1.
        this.renderer.setPixelRatio(window.devicePixelRatio); // Sets device pixel ratio. This is usually used for HiDPI
        // device to prevent bluring output canvas.
    }
}
exports.WebglRenderer = WebglRenderer;
