import React, {useMemo, useRef} from 'react';
import './model-viewer.scss';
import * as cnfStorage from '../redux/storages/configuration-storage'
import {useAppDispatch, useAppSelector} from "../redux/store";
import CloseButton from "./buttons/close-button";
import {useViewerInit} from '../hooks/use-viewer-init';
import {useViewerResize} from '../hooks/use-viewer-resize';
import * as appStorage from '../redux/storages/app-storage';
import LoadingAnimation from "./loading/LoadingCircle";
import {useTrans} from "../redux/storages/i18n-storage";
import RotateButton from './buttons/rotate-button';
import {AnnotationConfigName} from "../services/viewer-service";
import Annotations from "./annotations";
import {useSizeObserver} from "../hooks/use-size-observer";

interface IProps {
    fullSize?: boolean,
    horizontal?: boolean,
}

const styles = {
    loading: {
        background: "transparent radial-gradient(closest-side at 50% 50%, rgba(217, 223, 227, 1) 0%, rgba(177, 194, 201, 1) 100%) 0 0 no-repeat padding-box"
    }
}

const ModelViewer: React.FC<IProps> = ({fullSize = false, horizontal}) => {
    const dispatch = useAppDispatch();
    const wrpRef = useRef<HTMLDivElement>(null);
    const canvasParentRef = useRef<HTMLDivElement>(null);
    const dimension = useSizeObserver(wrpRef?.current);
    const configuration = useAppSelector(cnfStorage.selectors.getConfiguration);
    const zoom = (zoom: AnnotationConfigName | undefined) => dispatch(appStorage.actions.zoom(zoom));
    const selectedZoom = useAppSelector(appStorage.selectors.getSelectedZoom);
    const zooming = useAppSelector(appStorage.selectors.zooming);
    const initialized = useViewerInit({
        canvasParentRef,
        dimension,
    });
    useViewerResize(initialized, dimension);
    const rotate = (left: boolean) => dispatch(appStorage.actions.rotate(left));
    const initializedClass = initialized ? "cl-canvas-initialize" : ""
    const t = useTrans()

    const annotationNames = useMemo(() => {
        const names = [AnnotationConfigName.zoomPosDisplay];
        if(configuration && configuration.lightBox.has) {
            //names.push(AnnotationConfigName.zoomPosLight);
        }

        return names;
    }, [configuration])

    const backClickHandler = selectedZoom===AnnotationConfigName.zoomPosDisplay ? () => zoom(undefined) : ()=>{}

    return (
        <>
            {!initialized && <LoadingAnimation style={styles.loading}/>}
            <div id="cl-modelViewer" ref={wrpRef}>
                <div onClick={backClickHandler} ref={canvasParentRef} className={initializedClass}/>
                {
                    initialized && (
                        <Annotations
                            names={annotationNames}
                            visible={selectedZoom === undefined && !zooming}
                            onClick={zoom}
                        />
                    )
                }
                {
                    initialized && configuration && selectedZoom === undefined && !zooming && !fullSize && (
                        <>
                            <RotateButton
                                id="cl_modelViewer_rotateLeft"
                                className={horizontal ? "cl-responsive-button" : ""}
                                title={t('rotate.left')}
                                left={true}
                                onClick={() => {
                                    rotate(true);
                                }}
                            />
                            <RotateButton
                                id="cl_modelViewer_rotateRight"
                                className={horizontal ? "cl-responsive-button" : ""}
                                title={t('rotate.right')}
                                left={false}
                                onClick={() => {
                                    rotate(false);
                                }}
                            />
                        </>
                    )
                }
                {AnnotationConfigName.zoomPosDisplay === selectedZoom && (
                    <CloseButton title={t("back.button.text")} onClose={() => zoom(undefined)}/>
                )}
            </div>
        </>
    );
};

export default ModelViewer;