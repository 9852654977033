import React, {useEffect, useState} from 'react';
import AnnotationButton from "./buttons/annotation-button";
import {useTrans} from "../redux/storages/i18n-storage";
import {AnnotationConfigName} from "../services/viewer-service";
import {useAnnotationStyles} from "../hooks/use-annotation-styles";

interface IProps {
    names: AnnotationConfigName[],
    visible: boolean,
    onClick: (name?: AnnotationConfigName) => void,
}

const Annotations:React.FC<IProps> = ({names, visible, onClick}) => {
    const t = useTrans()
    const styles = useAnnotationStyles();

    if(!visible || !styles) {

        return null;
    }

    return (
        <>
            {names.map(name => {

                return (
                    <AnnotationButton
                        key={name}
                        style={styles[name]}
                        title={t(`annotation.${name}`)}
                        onClick={() => onClick(name)}
                    />
                )
            })}
        </>
    );
}

export default Annotations