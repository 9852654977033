import React from 'react';
import { ReactComponent as IconArrow3Left } from '../../assets/icons/images/icon-arrow3-left.svg';
import { ReactComponent as IconArrow3Right } from '../../assets/icons/images/icon-arrow3-right.svg';
import { ReactComponent as IconArSrc } from '../../assets/icons/images/icon-ar.svg';
import { ReactComponent as IconOkSrc } from '../../assets/icons/images/icon-ok.svg';
import { ReactComponent as IconCloseSrc } from '../../assets/icons/images/icon-close.svg';
import { ReactComponent as IconSnapshotSrc } from '../../assets/icons/images/icon-snapshot.svg';
import { ReactComponent as RedCircleSrc } from '../../assets/icons/images/red-circle.svg';
import { ReactComponent as IconBrushSrc } from '../../assets/icons/images/icon-brush.svg';
import { ReactComponent as IconUploadSrc } from '../../assets/icons/images/icon-upload.svg';
import { ReactComponent as IceDispenserSrc } from '../../assets/icons/images/iceDispenser.svg';
import { ReactComponent as PrinterShredderSrc } from '../../assets/icons/images/icon-printer-shredder.svg';
import { ReactComponent as IconTextSrc } from '../../assets/icons/images/icon-text.svg';
import { ReactComponent as IconImageSrc } from '../../assets/icons/images/icon-image.svg';
import { ReactComponent as NfcScannerSrc } from '../../assets/icons/images/icon-nfc-scanner.svg';
import { ReactComponent as ThermoPrinterSrc } from '../../assets/icons/images/icon-printer.svg';
import { ReactComponent as IconCardDispenserSrc } from '../../assets/icons/images/icon-card-dispenser.svg';
import { ReactComponent as IconKeyboardSrc } from '../../assets/icons/images/icon-keyboard.svg';
import { ReactComponent as IconLightboxSrc } from '../../assets/icons/images/icon-lightbox.svg';
import { ReactComponent as IconScreenPrivacyFilterSrc } from '../../assets/icons/images/icon-screen-privacy-filter.svg';
import { ReactComponent as IconGenerateLinkSrc } from '../../assets/icons/images/icon-link.svg';
import { ReactComponent as IconKioskColorSrc } from '../../assets/icons/images/icon-color-kiosk.svg';
import { ReactComponent as IconLegColorSrc } from '../../assets/icons/images/icon-color-leg.svg';
import { ReactComponent as IconWallMountSrc } from '../../assets/icons/images/icon-wallMount.svg';
import { ReactComponent as IconLegRoundSrc } from '../../assets/icons/images/icon-leg-round.svg';
import { ReactComponent as IconLegSquareSrc } from '../../assets/icons/images/icon-leg-scuare.svg';
import { ReactComponent as IconBarCodeScannerSrc } from '../../assets/icons/images/icon-bar-code-scanner.svg';
import { ReactComponent as IconDoubleSidedSrc } from '../../assets/icons/images/icon-doublesided.svg';

interface IProps {
    [index: string]: any,
}

export const IconAr: React.FC<IProps> = (props) => <IconArSrc {...props} />
export const IconBrush: React.FC<IProps> = (props) => <IconBrushSrc {...props} />
export const IconClose: React.FC<IProps> = (props) => <IconCloseSrc {...props} />
export const IconOk: React.FC<IProps> = (props) => <IconOkSrc {...props} />
export const IconRotateLeft: React.FC<IProps> = (props) => <IconArrow3Left {...props} />
export const IconRotateRight: React.FC<IProps> = (props) => <IconArrow3Right {...props} />
export const IconSnapshot: React.FC<IProps> = (props) => <IconSnapshotSrc {...props} />
export const IconUpload: React.FC<IProps> = (props) => <IconUploadSrc {...props} />
export const RedCircle: React.FC<IProps> = (props) => <RedCircleSrc {...props} />
export const ThermoPrinter: React.FC<IProps> = (props) => <ThermoPrinterSrc {...props} />
export const PrinterShredder: React.FC<IProps> = (props) => <PrinterShredderSrc {...props} />
export const NfcScanner: React.FC<IProps> = (props) => <NfcScannerSrc {...props} />
export const IconCardDispenser: React.FC<IProps> = (props) => <IconCardDispenserSrc {...props} />
export const IconKeyboard: React.FC<IProps> = (props) => <IconKeyboardSrc {...props} />
export const IconLightbox: React.FC<IProps> = (props) => <IconLightboxSrc {...props} />
export const IceDispenser: React.FC<IProps> = (props) => <IceDispenserSrc {...props} />
export const IconText: React.FC<IProps> = (props) => <IconTextSrc {...props} />
export const IconImage: React.FC<IProps> = (props) => <IconImageSrc {...props} />
export const IconScreenPrivacyFilter: React.FC<IProps> = (props) => <IconScreenPrivacyFilterSrc {...props} />
export const IconGenerateLink: React.FC<IProps> = (props) => <IconGenerateLinkSrc {...props} />
export const IconBarCodeScanner: React.FC<IProps> = (props) => <IconBarCodeScannerSrc {...props} />
export const IconKioskColor: React.FC<IProps> = (props) => <IconKioskColorSrc {...props} />
export const IconLegColor: React.FC<IProps> = (props) => <IconLegColorSrc {...props} />
export const IconWallMount: React.FC<IProps> = (props) => <IconWallMountSrc {...props} />
export const IconLegRound: React.FC<IProps> = (props) => <IconLegRoundSrc {...props} />
export const IconLegSquare: React.FC<IProps> = (props) => <IconLegSquareSrc {...props} />
export const IconDoubleSided: React.FC<IProps> = (props) => <IconDoubleSidedSrc {...props} />

export const getIcon = (key: string):React.FC<IProps> => {
    switch(key) {
        case 'print_thermo': return (props: any) => <ThermoPrinterSrc {...props} />
        case 'print_shredder': return (props: any) => <PrinterShredderSrc {...props} />
        case 'square_leg': return (props: any) => <IconLegSquareSrc {...props} />
        case 'round_leg': return (props: any) => <IconLegRoundSrc {...props} />
        case 'wall_mount': return (props: any) => <IconWallMountSrc {...props} />

    }
    throw new Error(`Icon "${key} not found!`)
}
