import React from 'react';
import classNames from 'classnames';
import './animated-hide.scss';

export interface IProps {
    hidden: boolean,
    direction?: Direction,
}

export enum Direction {
    top='top',
    right='right',
    bottom='bottom',
    left='left'
}

const AnimatedHide: React.FC<IProps> = ({hidden, direction = Direction.left, children, ...props}) => {
    const controlClasses = classNames(
        'cl-animatedHide',
        hidden && 'cl-animatedHide-' + direction,
    )

    return (
        <div className={controlClasses} {...props}>
            {children}
        </div>
    );
}

export default AnimatedHide