import React, {useState} from 'react';
import Popup, {Position} from "./popup";
import ColorPickerPopup from './color-picker-popup';
import {useTrans} from '../../redux/storages/i18n-storage';
import * as cnfStorage from '../../redux/storages/configuration-storage'
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {IModelConfiguration} from "../../model.schema";

interface IProps {
    configuration: IModelConfiguration,
    isBody: boolean,
    onClose: () => void,
}

const ColorPickerPopupContainer: React.FC<IProps> = ({configuration, isBody, onClose}) => {
    const dispatch = useAppDispatch();
    const t = useTrans();
    let colorValue = useAppSelector(cnfStorage.selectors.getCurrentColorValue);
    let legColorValue = useAppSelector(cnfStorage.selectors.getCurrentLegColorValue);

    if(undefined === colorValue) {
        colorValue = '#fff';
    }
    if(undefined === legColorValue) {
        legColorValue = '#aaa';
    }
    const name = isBody ? configuration.material.name : configuration.legMaterial.name;
    const [initialValue] = useState<string>(colorValue);
    const [initialLegValue] = useState<string>(legColorValue);

    const closeHandler = () => {
        isBody ? dispatch(cnfStorage.actions.setColor(name, initialValue, initialLegValue)) : dispatch(cnfStorage.actions.setLegColor(name, initialValue, initialLegValue));
        onClose();
    }

    const changeHandler = (value: string) => {
        isBody ? dispatch(cnfStorage.actions.setColor(name, value, legColorValue!)) : dispatch(cnfStorage.actions.setLegColor(name, colorValue!, value));
    }

    return (
        <Popup
            position={Position.right}
            onSubmit={onClose}
            onClose={closeHandler}
            fullHeight={false}
        >
            <ColorPickerPopup
                t={t}
                color={isBody ? colorValue : legColorValue}
                onChange={changeHandler}
            />
        </Popup>
    );
}

export default ColorPickerPopupContainer