export const debug = () => process.env.NODE_ENV === `development`;
export const debugLog = (...args: any) => {

    if(debug()) {
        console.log(...args)
    }
}

export const isHexString = (hexStr: string) => {

    return /^#[0-9A-Fa-f]{6}$/.test(hexStr);
}

export const supportsIosARQuickLook = () => {

    return false;
    // chrome on ios supports attribute ar but does not show ios quick ar lood
    // const a = document.createElement('a');
    //
    // return a.relList.supports('ar');
}

export const stripTrailingSlash = (str: string) => str.replace(/\/$/, "");

export const responseStatus = (resp: Response) => {
    if (!resp.ok) {
        console.log('Request fails with response: ', resp);

        throw new Error('Request fails.');
    }

    return resp;
}

export const isMobileDevice = () => {
    return ( 'ontouchstart' in window ) ||
        ( navigator.maxTouchPoints > 0 ) ;
}