"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShareService = void 0;
class ShareService {
    constructor(serializer, urlHelper, storage, embeddedSrv) {
        this.serializer = serializer;
        this.urlHelper = urlHelper;
        this.storage = storage;
        this.embeddedSrv = embeddedSrv;
    }
    fetch() {
        return __awaiter(this, void 0, void 0, function* () {
            if (undefined === this.cached) {
                this.cached = false;
                let serialized;
                const key = this.urlHelper.getKey();
                if (key) {
                    try {
                        serialized = yield this.storage.get(key);
                    }
                    catch (e) {
                        console.log('ERROR: Could not load configuration.');
                    }
                    if (serialized) {
                        this.cached = this.serializer.unserialize(serialized);
                    }
                }
            }
            return this.cached;
        });
    }
    makeLink(obj) {
        return __awaiter(this, void 0, void 0, function* () {
            const serialized = this.serializer.serialize(obj);
            const key = yield this.storage.set(serialized);
            return this.urlHelper.createLink(key);
        });
    }
    reload() {
        const location = this.urlHelper.getLocation();
        const link = this.urlHelper.createLink();
        if (this.embeddedSrv.embedded) {
            this.embeddedSrv.redirect(link);
        }
        else {
            location.replace(link);
        }
    }
}
exports.ShareService = ShareService;
