"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CanFileStorage = void 0;
const object_hash_1 = require("object-hash");
const hash_converter_1 = require("../../../utils/hash-converter");
class CanFileStorage {
    constructor(canFileRequests, keyPrefix = '') {
        this.canFileRequests = canFileRequests;
        this.keyPrefix = keyPrefix;
        this.get = (key) => __awaiter(this, void 0, void 0, function* () {
            const storageKey = this.keyPrefix + key;
            const respH = yield this.canFileRequests.hasFile(storageKey);
            if (!respH.ok) {
                console.log('Request fails with response: ', respH);
                throw new Error('Request fails.');
            }
            const resH = yield respH.json();
            if (!resH.data.hasFile) {
                return;
            }
            const respD = yield this.canFileRequests.download(storageKey);
            if (!respD.ok) {
                console.log('Request fails with response: ', respD);
                throw new Error('Request fails.');
            }
            return (yield respD.blob()).text();
        });
    }
    set(serialized) {
        return __awaiter(this, void 0, void 0, function* () {
            const blob = new Blob([serialized], { type: 'application/json' });
            const key = (0, hash_converter_1.hexToLetterDigits)((0, object_hash_1.MD5)(serialized)).substring(0, 6);
            const storageKey = this.keyPrefix + key;
            const resp = yield this.canFileRequests.upload(storageKey, blob);
            if (!resp.ok) {
                console.log('Request fails with response: ', resp);
                throw new Error('Request fails.');
            }
            return key;
        });
    }
}
exports.CanFileStorage = CanFileStorage;
