import React from 'react';
import ReactDOM from 'react-dom';
import App, {IProps} from './App';
import reportWebVitals from './reportWebVitals';
import {StoreProvider} from "./redux/store";

const Index: React.FC<IProps> = (props) => {

    return (
        <React.StrictMode>
            <StoreProvider>
                <App {...props}/>
            </StoreProvider>
        </React.StrictMode>
    )
}

(function(tag: string, Comp:React.FC<IProps>) {
    document.createElement(tag);
    const nodes = Array.from(document.getElementsByTagName(tag));
    nodes.forEach((node) => {

        ReactDOM.render(<Comp/>, node)
    });
})('cl-configurator', Index)

// ReactDOM.render(
//     <React.StrictMode>
//         <StoreProvider>
//             <App/>
//         </StoreProvider>
//     </React.StrictMode>,
//     document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
