import {applyMiddleware, createStore} from 'redux'
import reducer from './reducer'
import thunk from 'redux-thunk';
import type {ThunkMiddleware} from 'redux-thunk'
import {createLogger} from 'redux-logger';
import {debug} from '../../services/utils';

export const store = debug()
    ? createStore(reducer, applyMiddleware(thunk as ThunkMiddleware<any, any>, createLogger()))
    : createStore(reducer, applyMiddleware(thunk as ThunkMiddleware<any, any>));
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;