import {RefObject, useEffect, useState} from "react";
import {IDimensions} from "./use-size";
import * as cnfStorage from '../redux/storages/configuration-storage';
import {viewerService} from '../services/viewer-service';
import {useAppDispatch} from "../redux/store";
import {debugLog} from '../services/utils';
import {initAppEngine} from "../services/engine-service";

interface IViewerOptions {
    canvasParentRef: RefObject<HTMLDivElement>,
    dimension: IDimensions,
}

export const useViewerInit = (options: IViewerOptions) => {
    const {canvasParentRef, dimension} = options;
    const [initialized, setInitialized] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    // viewer initialization
    useEffect(() => {
        if (!canvasParentRef.current) {

            throw new Error('Missing canvas element.');
        }
        (async () => {
            try {
                const configuration = await initAppEngine();
                await viewerService.init({
                    parent: canvasParentRef.current,
                    ...dimension,
                    onZoomPosChange: () => {},
                })
                dispatch(cnfStorage.actions.initialize(configuration))
                debugLog('use viewer: start viewer');
                viewerService.viewer.start();
                setInitialized(true);
            } catch (e) {
                console.error('Could not initialize 3D viewer', e)
            }
        })();
    }, []);

    return initialized;
}