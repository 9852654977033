import * as THREE from 'three';
import { MeshStandardMaterial } from 'three';
import {
    AnnotationOnChange,
    CanvasTexture,
    createAndAppendCanvas,
    DefaultImageRenderer,
    EmptyAnnotationManager,
    GltfExporter,
    gltfLoad,
    IDimensions2D,
    ImageCenterCanvasRenderer,
    imageLoad,
    ImageStretchCanvasRenderer,
    mergeConfigurations,
    MultiLineTextCanvasRenderer,
    OrbitControlsCamera,
    OrbitTween,
    RotateTween,
    textureLoad,
    Tween,
    UsdzExporter,
    WebglRenderer
} from '@canvas-logic/three-viewer';
import { MaterialLibrary, MaterialTempUpdate, SceneManager } from '@canvas-logic/scene-logic-v1';
import * as viewerSrv from '@canvas-logic/configurator-base/lib/services/viewer-service';
import { IConfiguration } from './configuration-types';

export interface IMaterialAssignments {
    [index: string]: string,
}

interface IThreeViewerOptions {
    parent?: HTMLElement,
    width: number,
    height: number,
    annotationOnChange: AnnotationOnChange,
}

export interface IViewerConfiguration {
    root: string,
    modelsUrl: string,
    materialsUrl: string,
    hdrUrl: string,
}

const defaultOptions: IThreeViewerOptions = {
    width: 0,
    height: 0,
    annotationOnChange: () => {
    },
}

const defaultConfiguration: Partial<IConfiguration> = {
    viewer: {
        root: 'root',
        modelsUrl: 'assets/geometries/models.glb',
        materialsUrl: 'assets/geometries/materials.glb',
        hdrUrl: 'assets/textures/background.hdr',
    },
    lightBox: {
        backgroundImagePath: 'assets/images/light_box_front.png'
    }
}

class ThreeViewer {
    private options!: IThreeViewerOptions;
    private configuration!: Partial<IConfiguration>;
    public camera!: OrbitControlsCamera;
    private annotationManager!: EmptyAnnotationManager;
    private renderer!: THREE.WebGLRenderer;
    private scene!: THREE.Scene;
    private canvas!: HTMLCanvasElement;
    private sceneManager!: SceneManager;
    private canvasTexture!: CanvasTexture;
    private imageCenterCanvasRenderer!: ImageCenterCanvasRenderer;
    private imageStretchCanvasRenderer!: ImageStretchCanvasRenderer;
    private multiLineTextCanvasRenderer!: MultiLineTextCanvasRenderer;
    private lightBoxImg!: HTMLImageElement;

    /**
     * Initialize.
     */
    async init(options: Partial<IThreeViewerOptions>, configuration: Partial<IConfiguration> = {}) {
        this.options = {
            ...defaultOptions,
            ...options,
        };
        this.configuration = mergeConfigurations(defaultConfiguration, configuration);
        const viewerConfiguration: IViewerConfiguration = this.configuration.viewer as IViewerConfiguration;
        const {parent, width, height} = this.options;
        if (!parent) {
            console.log('ERROR: parent is not defined.')

            return;
        }
        this.canvas = createAndAppendCanvas(parent);
        this.scene = new THREE.Scene();
        this.camera = new OrbitControlsCamera(
          this.canvas,
          {width, height},
          configuration.camera ?? {},
        )

        this.renderer = (new WebglRenderer(this.canvas, configuration.renderer)).renderer;
        this.renderer.setSize(width, height);

        const [texture, scene] = await Promise.all([
            textureLoad(viewerConfiguration.hdrUrl, this.renderer),
            gltfLoad(viewerConfiguration.modelsUrl),
        ]);

        this.scene.environment = texture;
        const obj = scene.getObjectByName(viewerConfiguration.root);
        if (!obj) {
            console.log('ERROR: object with name root not found.')

            return;
        }
        this.scene.add(obj);

        const matScene = await gltfLoad(viewerConfiguration.materialsUrl);
        const materialLibrary = new MaterialLibrary();
        materialLibrary.addScene(matScene as THREE.Scene);
        this.sceneManager = new SceneManager(this.scene, materialLibrary);
        const spfMat = this.createSpfMaterial();
        if(spfMat) {
            materialLibrary.addMaterial(spfMat);
        }
        this.annotationManager = new EmptyAnnotationManager(this.scene, this.camera.camera)

        if (configuration.materialAssignments) {
            for (const [shadingGroupId, matName] of Object.entries(configuration.materialAssignments)) {
                this.sceneManager.updateMaterial(shadingGroupId, { matName }, false)
            }
        }

        // @ts-ignore
        this.lightBoxImg = await imageLoad(this.configuration.lightBox.backgroundImagePath);
        this.canvasTexture = new CanvasTexture(
          this.configuration.lightBox ? this.configuration.lightBox.canvasTexture : undefined
        );
        this.imageCenterCanvasRenderer = new ImageCenterCanvasRenderer(
          this.canvasTexture.context,
          this.configuration.lightBox ? this.configuration.lightBox.image : undefined
        );
        this.imageStretchCanvasRenderer = new ImageStretchCanvasRenderer(
          this.canvasTexture.context,
          this.configuration.lightBox ? this.configuration.lightBox.background : undefined
        );
        this.multiLineTextCanvasRenderer = new MultiLineTextCanvasRenderer(
          this.canvasTexture.context,
          this.configuration.lightBox ? this.configuration.lightBox.text : undefined
        );
        this.imageStretchCanvasRenderer.render(this.lightBoxImg);

        const lbObj = this.scene.getObjectByName('light') as THREE.Mesh;
        if (!lbObj) {
            console.log('ERROR: Object with name mat_light not found.')
        } else {
            // @ts-ignore
            lbObj.material = new MeshStandardMaterial({
                transparent: true,
                map: this.canvasTexture.texture

            })
        }
    }

    reinit(options: Partial<IThreeViewerOptions>, configuration: Partial<IConfiguration> = {}) {
        const opts = {
            ...this.options,
            ...options,
        }
        const config = mergeConfigurations(this.configuration, configuration)

        return this.init(opts, config)
    }

    start() {
        this.renderer.setAnimationLoop(this.animate);
    }

    get annotationOnChange() {

        return this.options.annotationOnChange;
    }

    set annotationOnChange(annotationOnChange) {
        this.options.annotationOnChange = annotationOnChange;
    }

    show(availableObjects: string[], objects: string[], materials: any[]) {
        this.sceneManager.setVisible(availableObjects, false)
        this.sceneManager.setVisible(objects, true)
        materials.forEach(mat => {
            this.sceneManager.updateMaterial(
              mat.shadingGroupId, {matName: mat.matName, aoMatName: mat.aoMatName}, false
            )
        })
        this.annotationManager.create(this.configuration?.annotations ?? []);
    }

    set colors(colors: any[]) {
        console.log('Set material colors', colors);
        colors.forEach(color => {
            this.sceneManager.setColor(color.shadingGroupId, color.colorValue);
        })
    }

    /**
     * Is called after canvas has resized.
     */
    resize(dimensions: IDimensions2D) {
        const {width, height} = dimensions;
        this.options.width = width;
        this.options.height = height;
        this.renderer.setSize(width, height);
        this.camera.resize({width, height})
    }

    async tweenTo(name: string) {
        if (this.configuration && this.configuration.tweens && this.configuration.tweens[name]) {
            const configuration = this.configuration.tweens[name]
            const tween = new OrbitTween(this.camera, this.scene, configuration);

            return await tween.tweenTo()
        }
        console.log(`ERROR: Tween configuration for ${name} not found.`)

        return Promise.resolve();
    }

    async rotate(left: boolean) {
        const tween = new RotateTween(this.scene, this.configuration.rotate);
        tween.left = left;
        await tween.tweenTo();
    }

    toImage() {
        const renderer = new DefaultImageRenderer(this.scene, this.configuration.imageRenderer);

        return renderer.render();
    }

    async exportGLTF() {
        const tmpUpdate = new MaterialTempUpdate(this.sceneManager);
        tmpUpdate.set('mat_display', {matName: 'm_display'});

        const sceneBox = new THREE.Box3();
        const sceneBoxSize = new THREE.Vector3();
        const rootScene = this.scene.getObjectByName('root');

        if (this.scene) {
            sceneBox.setFromObject(rootScene!);
            sceneBox.getSize(sceneBoxSize);
            const geometry = new THREE.BoxGeometry(0.01, 0.65, 0.01);
            const box = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({ color: 0xffff00, transparent: true, opacity: 0 }));
            box.position.y = 0.325;
            box.position.x = 0;
            box.position.z = 0;
            box.name = 'wall';
            const wallMount = this.scene.getObjectByName('wall_mount');
            if (wallMount) {
                wallMount.add(box);
            } else if (rootScene) {
                rootScene.add(box);
            }
        }

        const exporter = new GltfExporter(this.scene, this.configuration.gltfExport);
        const res = await exporter.export()
        tmpUpdate.restore();

        return res;
    }

    async exportUSDZ(): Promise<Blob> {
        console.log(this.configuration.usdzExport)
        const tmpUpdate = new MaterialTempUpdate(this.sceneManager);
        tmpUpdate.set('mat_display', { matName: 'm_display' });
        const exporter = new UsdzExporter(this.scene, this.configuration.usdzExport);
        const res = await exporter.export();
        tmpUpdate.restore();

        return res;
    }

    checkLightBoxTextWidth = (text: string) => {

        return this.multiLineTextCanvasRenderer.isTextWidthTooLong(text, 0.95)
    }

    clearLightBox() {
        this.imageCenterCanvasRenderer.context.clearRect(0, 0, this.imageCenterCanvasRenderer.context.canvas.width, this.imageCenterCanvasRenderer.context.canvas.width)
        this.imageStretchCanvasRenderer.render(this.lightBoxImg);
        this.canvasTexture.texture.needsUpdate = true;
    }

    drawLightBoxText(lines: string[]) {
        this.imageStretchCanvasRenderer.render(this.lightBoxImg);
        this.multiLineTextCanvasRenderer.render(lines);
        this.canvasTexture.texture.needsUpdate = true;
    }

    async drawLightBoxImage(image: HTMLImageElement) {
        try {
            this.imageStretchCanvasRenderer.context.clearRect(0, 0, this.imageCenterCanvasRenderer.context.canvas.width, this.imageCenterCanvasRenderer.context.canvas.width);
            this.imageStretchCanvasRenderer.render(this.lightBoxImg);
            this.imageCenterCanvasRenderer.render(image);
            this.canvasTexture.texture.needsUpdate = true;
        } catch (e) {
            console.log('Could not set image.');
            throw e;
        }
    }

    private createSpfMaterial() {
        const textureName = 'm_display';
        let textureMat = this.sceneManager.materialLibrary.get(textureName) as THREE.MeshStandardMaterial;
        if(!textureMat) {
            console.log(`ERROR: Material ${textureName} not found.`);

            return;
        }

        if(!textureMat.emissiveMap) {
            console.log(`ERROR: Emissive Map not found for material ${textureName}.`);

            return;
        }

        const texture = textureMat.emissiveMap

        return viewerSrv.createSpfMaterial(texture);
    }

    private animate = () => {
        this.camera.update();
        Tween.update();
        this.annotationManager.update(
          this.annotationOnChange,
          {width: this.options.width, height: this.options.height}
        );
        this.renderer.render(this.scene, this.camera.camera);
    }
}

export default ThreeViewer;