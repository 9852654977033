import { createEmbeddedService, EmbeddedService } from '@canvas-logic/configurator-base/lib/services/embedded-service';

let embeddedService: EmbeddedService | undefined = undefined;

export const getEmbeddedService = async () => {
  if(embeddedService === undefined) {
    embeddedService = await createEmbeddedService();
  }

  return embeddedService;
}