import React from 'react';
import classNames from 'classnames';
import './grabbing-space.scss';

interface IProps {
    className?:string,
    [index: string]: any,
}

const GrabbingSpace:React.FC<IProps> = ({className, ...props}) => {
    const classes = classNames(
        className,
        'cl-grab-handle');
    const isShown = true;

    return (
        <>
            {isShown && <div className={classes} {...props}>
                <i className="cl-icono-arrow-up"></i>
                <i className="cl-icono-between"></i>
                <i className="cl-icono-arrow-down"></i>
            </div> }
        </>
    )
}

export default GrabbingSpace