import * as t from './action-types';
import {IModelConfiguration} from "../../../model.schema";
import {mutateAppEngine, availableMaterials, getInitialLightBoxText} from "../../../services/engine-service";
import {ThunkAction} from "redux-thunk";
import {RootState} from "../../store";
import {AnyAction} from "redux";
import * as selectors from './selectors';
import {viewerService} from "../../../services/viewer-service";
import {ILightBox, LightBoxMode} from "./reducer";
import { getShareService } from '../../../services/share-service';

export const initialize = (configuration: IModelConfiguration): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) =>  {
    const shareSrv = await getShareService();
    const model = await shareSrv.fetch();
    const mats = availableMaterials(configuration)
    const colors = [];
    let colorValue;
    let legColorValue;
    for(let i = 0; i<mats.length; i++) {
        if(mats[i].model.color.enabled) {
            const name = mats[i].model.name;
            let value;
            if (model) {
                if(model.model.material === name) {
                    value = model.model.colorValue;
                }
                else {
                    value = model.model.legColorValue;
                }
            } else {
                value = mats[i].model.color.default ?? '#fff'
            }

            if(configuration.material.name === name) {
                colorValue = value;
            }
            if(configuration.legMaterial.name === name) {
                legColorValue = value;
            }
            colors.push([name, value])
        }
    }
    viewerService.showConfiguration(configuration, colorValue, legColorValue);
    //viewerService.showLegConfiguration(configuration, colorValue);

    let lightBox: ILightBox = {
        mode: LightBoxMode.text,
        text: [getInitialLightBoxText(configuration) ?? ''],
        image: undefined,
    }
    if(model && model.model.lightBox) {
        lightBox = model.model.lightBox;
    }
    await viewerService.drawLightBox(lightBox)

    dispatch({
        type: t.INITIALIZE,
        colors,
        configuration,
        lightBox,
    })
}

export const mutateConfiguration = (lookupId: string, value: any): ThunkAction<void, RootState, unknown, AnyAction> => (dispatch, getState) => {
    const configuration = selectors.getConfiguration(getState());

    if (typeof configuration === undefined) {
        alert('error')

        return;
    }

    const [newConfiguration, validationResult] = mutateAppEngine(configuration!, lookupId, value);

    if (validationResult.isValid) {
        const newConf = newConfiguration as IModelConfiguration;
        dispatch(setConfiguration(newConf));
        const colorValue = selectors.getCurrentColorValue(getState());
        const legColorValue = selectors.getCurrentLegColorValue(getState());
        viewerService.showConfiguration(newConf, colorValue, legColorValue);
    } else {
        alert(validationResult.errorMessage)
    }
}

export const setColor = (name: string, value: string, legValue: string): ThunkAction<void, RootState, unknown, AnyAction> => (dispatch, getState) => {
    const configuration = selectors.getConfiguration(getState());
    if(!configuration) {
        console.log('ERROR: configuration is not defined.')
    }
    else {
        viewerService.showConfiguration(configuration, value, legValue);
    }

    dispatch({
        type: t.SET_COLOR,
        name,
        value,
    })
}

export const setLegColor = (name: string, value: string, legValue: string): ThunkAction<void, RootState, unknown, AnyAction> => (dispatch, getState) => {
    const configuration = selectors.getConfiguration(getState());

    if(!configuration) {
        console.log('ERROR: configuration is not defined.')
    }
    else {
        viewerService.showConfiguration(configuration, value, legValue);
    }

    dispatch({
        type: t.SET_LEG_COLOR,
        name,
        legValue,
    })
}

export const setLightBox = (lightBox: ILightBox, onImageUploadError: () => void = () => {}): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    await viewerService.drawLightBox(lightBox, onImageUploadError)
    dispatch({
        type: t.SET_LIGHT_BOX,
        lightBox
    });
}

const setConfiguration = (configuration: IModelConfiguration) => ({
    type: t.SET,
    configuration
})