"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostService = void 0;
class PostService {
    get embedded() {
        return window !== window.parent;
    }
    post(msg) {
        window.parent.postMessage(msg, '*');
    }
}
exports.PostService = PostService;
