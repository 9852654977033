import {useEffect, useState} from "react";

export interface IDimensions {
    width: number,
    height: number,
}

export const useSizeObserver = (element: HTMLElement|null) => {
    const [dimensions, setDimensions] = useState<IDimensions>({height: 0, width: 0})
    useEffect(() => {
        const handleResize = () => {
            if (element) {
                setDimensions({height: element.clientHeight, width: element.clientWidth})
            }
        }
        const observer = new ResizeObserver(handleResize)
        if (element) {
            observer.observe(element);
        }
        handleResize();

        return () => {
            observer.disconnect();
        }
    }, [element]);

    return dimensions;
}