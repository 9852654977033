import React, {useEffect, useState} from 'react';
import HighResPopup, {IDimensions} from './high-res-popup';
import {viewerService} from '../../services/viewer-service';
import {useTrans} from '../../redux/storages/i18n-storage';
import Popup, {Position} from "./popup";
import * as cnfStorage from '../../redux/storages/configuration-storage'
import {useAppSelector} from "../../redux/store";

export interface IProps {
    onClose: () => void,
    dimensions: IDimensions,
}

const DELAY = 250;

const HighResPopupContainer: React.FC<IProps> = (props) => {
    const t = useTrans();
    const [src, setSrc] = useState<string | undefined>();
    const configuration = useAppSelector(cnfStorage.selectors.getConfiguration);
    useEffect(() => {
        if(!configuration) {

            return;
        }
        setTimeout(() => {
            viewerService.toImage(configuration)
                .then((src) => {
                    setSrc(src);
                })
                .catch(() => {
                    props.onClose()
                })
        }, DELAY)

    }, []);

    return (
        <Popup
            transparentOverlay={false}
            onClose={props.onClose}
            position={Position.full}
        >
            <HighResPopup {...props} src={src} t={t}/>
        </Popup>
    );
}

export default HighResPopupContainer;