import './icon-text-button.scss';
import * as React from 'react';
import classNames from 'classnames'

interface iProps {
    children: React.ReactNode,
    className?: string,
    label: string,
    onClick: any,
    isSelected: boolean,
    disabled?: boolean,
    shrinkIfMobile?: boolean,
    [index: string]: any,
}

const noop = () => {}

const IconTextButton:React.FC<iProps> = ({children, className, label, onClick, isSelected, disabled= false, shrinkIfMobile= false}) => {
  const classes = classNames(
      "cl-iconTextButton",
      (isSelected ? "cl-button-selected" : "cl-button-unselected"),
      (disabled ? "cl-button-disabled":"cl-button-available"),
      (shrinkIfMobile ? "cl-button-shrink" : "cl-button-fixed"),
      className
  )

  return (
    <div title={label} className={classes} onClick={disabled ? noop : onClick}>
        {children}
        <span className="cl-iconTextButton-text">
            {label}
        </span>
    </div>
  );
}

export default IconTextButton;
