"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultImageRenderer = void 0;
const loaders_1 = require("../loaders");
const perspective_camera_1 = require("../cameras/perspective-camera");
const webgl_renderer_1 = require("../renderers/webgl-renderer");
const merge_configurations_1 = require("../utils/merge-configurations");
const defaultConfiguration = {
    output: {
        dimensions: {
            width: 1920,
            height: 1080,
        },
        quality: 0.8,
        mime: 'image/jpeg',
    },
    scene: {
        root: 'root',
        rotation: {
            x: undefined,
            y: undefined,
            z: undefined,
        },
        hidden: [],
    },
    camera: {
        fov: 30,
        near: 100,
        far: 1000,
        position: {
            x: 0,
            y: 0,
            z: 0
        },
        lookAt: {
            x: 0,
            y: 0,
            z: 0
        },
    },
    renderer: Object.assign({}, webgl_renderer_1.defaultConfiguration),
    backgroundImages: [],
    watermarkImages: [],
};
class DefaultImageRenderer {
    constructor(scene, configuration = {}) {
        this.scene = scene;
        this.configuration = (0, merge_configurations_1.mergeConfigurations)(defaultConfiguration, configuration);
        this.origEnv = null;
    }
    render() {
        return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
            const renderer = (new webgl_renderer_1.WebglRenderer(undefined, this.configuration.renderer)).renderer;
            const backgroundImages = yield this.loadImages(this.configuration.backgroundImages);
            const watermarkImages = yield this.loadImages(this.configuration.watermarkImages);
            const obj = this.scene.getObjectByName(this.configuration.scene.root);
            if (!obj) {
                console.log(`ERROR: Object with name ${this.configuration.scene.root} not found.`);
                throw new Error(`Object with name ${this.configuration.scene.root} not found.`);
            }
            const { dimensions } = this.configuration.output;
            this.rotate(obj, false);
            this.hide(this.configuration.scene.hidden, false);
            const shotCamera = (new perspective_camera_1.PerspectiveCamera(dimensions, this.configuration.camera)).camera;
            const bgCanvasCtx = document.createElement('canvas').getContext('2d');
            if (!bgCanvasCtx) {
                console.log(`ERROR: Could not create canvas.`);
                reject(`Could not create canvas.`);
                return;
            }
            bgCanvasCtx.canvas.width = dimensions.width;
            bgCanvasCtx.canvas.height = dimensions.height;
            // fix bug in android's Chrome browser (wrong position of kiosk in high resolution image)
            renderer.setPixelRatio(1);
            // end fix
            renderer.setSize(dimensions.width, dimensions.height);
            yield this.setEnvironment(renderer, false);
            renderer.render(this.scene, shotCamera);
            const renderImg = new Image();
            renderImg.src = renderer.domElement.toDataURL();
            renderImg.onload = () => {
                this.drawImages(bgCanvasCtx, backgroundImages, this.configuration.backgroundImages);
                bgCanvasCtx.drawImage(renderImg, 0, 0, dimensions.width, dimensions.height);
                this.drawImages(bgCanvasCtx, watermarkImages, this.configuration.watermarkImages);
                this.setEnvironment(renderer, true);
                this.rotate(obj, true);
                this.hide(this.configuration.scene.hidden, true);
                renderer.dispose();
                resolve(bgCanvasCtx.canvas.toDataURL(this.configuration.output.mime, this.configuration.output.quality));
            };
        }));
    }
    loadImages(images) {
        return Promise.all(images.map((img) => {
            try {
                return (0, loaders_1.imageLoad)(img.path);
            }
            catch (e) {
                console.log(`ERROR: Image "${img.path}" could not be loaded.`, e);
                return new HTMLImageElement();
            }
        }));
    }
    rotate(obj, restore) {
        if (!restore) {
            this.origRotation = {
                x: obj.rotation.x,
                y: obj.rotation.y,
                z: obj.rotation.z,
            };
            if (undefined !== this.configuration.scene.rotation.x) {
                obj.rotation.x = this.configuration.scene.rotation.x;
            }
            if (undefined !== this.configuration.scene.rotation.y) {
                obj.rotation.y = this.configuration.scene.rotation.y;
            }
            if (undefined !== this.configuration.scene.rotation.z) {
                obj.rotation.z = this.configuration.scene.rotation.z;
            }
        }
        else if (this.origRotation) {
            obj.rotation.x = this.origRotation.x;
            obj.rotation.y = this.origRotation.y;
            obj.rotation.z = this.origRotation.z;
        }
    }
    hide(names, restore) {
        names.forEach((name) => {
            const object = this.scene.getObjectByName(name);
            if (object) {
                object.visible = restore;
            }
            else {
                console.log(`ERROR: Object with name ${name} not found.`);
            }
        });
    }
    setEnvironment(renderer, restore) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.configuration.scene.hdr) {
                return;
            }
            if (!restore) {
                const texture = yield (0, loaders_1.textureLoad)(this.configuration.scene.hdr, renderer);
                this.origEnv = this.scene.environment;
                this.scene.environment = texture;
            }
            else {
                this.scene.environment = this.origEnv;
            }
        });
    }
    drawImages(bgCanvasCtx, images, configuration) {
        images.forEach((img, i) => {
            if (img) {
                const bgrImage = configuration[i];
                if (bgrImage.destDimensions) {
                    bgCanvasCtx.drawImage(img, bgrImage.destPosition.x, bgrImage.destPosition.y, bgrImage.destDimensions.width, bgrImage.destDimensions.height);
                }
                else {
                    bgCanvasCtx.drawImage(img, bgrImage.destPosition.x, bgrImage.destPosition.y);
                }
            }
            else {
                console.log('ERROR: Image is not defined.');
            }
        });
    }
}
exports.DefaultImageRenderer = DefaultImageRenderer;
