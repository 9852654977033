import React, {useEffect, useRef} from 'react';
import QRCode, {QRCodeRenderersOptions} from 'qrcode'

export interface IProps {
    text: string,
    options?: QRCodeRenderersOptions,
    [index:string]: any,
}

const defaultOptions: QRCodeRenderersOptions = {
    width: 200,
    margin: 0,
}

const QrCodeCanvas: React.FC<IProps> = ({text, options= {}, ...props}) => {
    const ref = useRef<HTMLCanvasElement>(null);
    const opts = {...defaultOptions, ...options};

    useEffect(() => {
        ref && ref.current && QRCode.toCanvas(ref.current, text, opts, (error) => {
           if(error) {
               console.log("Error: Can't create QR code.")
           }
        })
    }, [ref, text, options]);


    return (
        <canvas {...props} ref={ref}/>
    )
}

export default QrCodeCanvas;