import './popup.scss'
import * as React from 'react';
import PopupCloseButton from "../buttons/popup-close-button";
import PopupConfirmButton from "../buttons/popup-confirm-button";
import {useTrans} from "../../redux/storages/i18n-storage";
import Overlay from '../overlay';
import {useEscKeyDown} from "../../hooks/use-esc-key-down";
import {useRef} from "react";
import {useOutsideClick} from "../../hooks/use-outside-click";

interface iProps {
    children: any,
    onSubmit?: () => void,
    onClose: () => void,
    position?: Position,
    fullHeight?: boolean,
    transparentOverlay?: boolean,
}

export enum Position {
    left = 'left',
    right = 'right',
    full = 'full'
}

const Popup: React.FC<iProps> = ({
                                     children,
                                     onSubmit,
                                     onClose,
                                     position = Position.left,
                                     fullHeight = true,
                                     transparentOverlay = true
                                 }) => {
    const t = useTrans();
    const ref = useRef<HTMLDivElement>(null);
    const positionClass = 'cl-popup-' + position;
    const contentStyles = {
        height: fullHeight ? '100%' : 'auto',
    }
    useEscKeyDown(onClose);
    useOutsideClick(ref, onClose)

    return (
        <div className="cl-popup">
            <div ref={ref}>
                <div className={"cl-popup-popup " + positionClass}>
                    <div className="cl-popup-inner">

                        <div style={contentStyles} className="cl-popup-content">
                            {children}
                        </div>
                        {onSubmit ? <PopupConfirmButton title={t("modelviewer.lightbox.text.input.apply")}
                                                        onClick={onSubmit}/> : null}
                        <PopupCloseButton title={t("close.button.text")} onClose={onClose}/>
                    </div>
                </div>
            </div>
            <Overlay style={{zIndex: 21}} transparent={transparentOverlay}/>
        </div>
    );
};

export default Popup;
