import './close-button.scss';
import * as React from 'react';
import {IconClose} from "../icons/svgs";
import {useEffect} from "react";
import classNames from 'classnames'

interface iProps {
    className?: string,
    onClose: () => void,
    title: string
}

const CloseButton: React.FC<iProps> = ({className, ...props}) => {
    useEffect(() => {
            const close = (e: any) => {

                if (e.keyCode === 27) {
                    props.onClose();
                }
            }
            window.addEventListener('keydown', close)

            return () => window.removeEventListener('keydown', close)
    }, []);

    const classes = classNames(
        "cl-close-button",
        className
    )

    return (
        <div onClick={props.onClose} className={classes} >
            <IconClose {...props} />
        </div>
    )
}

export default CloseButton;