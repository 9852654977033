"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageCenterCanvasRenderer = void 0;
const merge_configurations_1 = require("../utils/merge-configurations");
const defaultConfiguration = {
    globalCompositeOperation: 'source-over',
    width: 1,
    height: 1,
};
class ImageCenterCanvasRenderer {
    constructor(context, configuration = {}) {
        this.context = context;
        this.configuration = (0, merge_configurations_1.mergeConfigurations)(defaultConfiguration, configuration);
    }
    render(image) {
        const width = this.context.canvas.width;
        const height = this.context.canvas.height;
        const fitWidth = width * this.configuration.width;
        const fitHeight = height * this.configuration.height;
        const origWidth = image.naturalWidth;
        const origHeight = image.naturalHeight;
        const scale = Math.min(fitWidth / origWidth, fitHeight / origHeight);
        const imgWidth = origWidth * scale;
        const imgHeight = origHeight * scale;
        // @ts-ignore
        this.context.globalCompositeOperation = this.configuration.globalCompositeOperation;
        this.context.drawImage(image, (width - imgWidth) / 2, (height - imgHeight) / 2, imgWidth, imgHeight);
    }
}
exports.ImageCenterCanvasRenderer = ImageCenterCanvasRenderer;
