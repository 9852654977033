"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialLibrary = void 0;
class MaterialLibrary {
    constructor() {
        this.map = new Map();
    }
    addScene(scene) {
        scene.traverse((child) => {
            if (!child.isMesh) {
                return;
            }
            this.map.set(child.material.name, child.material);
        });
    }
    addMaterial(mat) {
        this.map.set(mat.name, mat);
    }
    get(name) {
        return this.map.get(name);
    }
}
exports.MaterialLibrary = MaterialLibrary;
