import * as t from './action-types';
import type {AnyAction} from 'redux'
import {IModelConfiguration} from "../../../model.schema";

export enum LightBoxMode {
    'text',
    'image',
}

export interface ILightBox {
    mode: LightBoxMode,
    text: string[],
    image: File|undefined,
}

export interface IState {
    configuration: IModelConfiguration | undefined,
    colors: Map<string, string>,
    lightBox: ILightBox,
}

const initialState: IState = {
    configuration: undefined,
    colors: new Map(),
    lightBox: {
        mode: LightBoxMode.text,
        text: [],
        image: undefined,
    },
};

const reducer = function (state: IState = initialState, action: AnyAction): IState {
    const colors = new Map(state.colors);
    switch (action.type) {
        case t.INITIALIZE:

            return {
                colors: new Map(action.colors),
                configuration: action.configuration,
                lightBox: action.lightBox,
            }
        case t.SET:

            return {
                ...state,
                configuration: action.configuration,
            };
        case t.SET_COLOR:

            colors.set(action.name, action.value);
            return {
                ...state,
                colors,
            };
        case t.SET_LEG_COLOR:

            colors.set(action.name, action.legValue);
            return {
                ...state,
                colors,
            };
        case t.SET_LIGHT_BOX:

            return {
                ...state,
                lightBox: action.lightBox,
            };
    }

    return state
}

export default reducer;