import {useEffect, useState} from "react";

export interface IDimensions {
    width: number,
    height: number,
}

export const useSize = (element: HTMLElement|null) => {
    const [dimensions, setDimensions] = useState<IDimensions>({height: 0, width: 0})
    useEffect(() => {
        const handleResize = () => {
            if (element) {
                setDimensions({height: element.clientHeight, width: element.clientWidth})
            }
        }
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize)
    }, [element]);

    return dimensions;
}