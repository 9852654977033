"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnimationManager = void 0;
const THREE = require("three");
const merge_configurations_1 = require("../utils/merge-configurations");
const defaultConfiguration = {
    clips: [],
};
const defaultActionConfiguration = {
    loopStyle: THREE.LoopOnce,
    loopRepetitions: 1,
    clampWhenFinished: true,
};
class AnimationManager {
    constructor(rootObj, configuration = {}) {
        this.clock = new THREE.Clock();
        this.clips = [];
        this.play = (name, actionConfiguration = {}) => {
            return new Promise((resolve, reject) => {
                const finishedHandler = () => {
                    this.mixer.removeEventListener('finished', finishedHandler);
                    resolve();
                };
                this.mixer.addEventListener('finished', finishedHandler);
                this.playAnimation(name, actionConfiguration);
            });
        };
        this.playAnimation = (name, actionConfiguration = {}) => {
            const config = (0, merge_configurations_1.mergeConfigurations)(defaultActionConfiguration, actionConfiguration);
            this.mixer.stopAllAction();
            const clip = THREE.AnimationClip.findByName(this.clips, name);
            if (!clip) {
                console.log(`ERROR: Animation clip with name ${name} not found.`);
                return;
            }
            const action = this.mixer.clipAction(clip);
            if (!action) {
                console.log(`ERROR: Could not create clip action for name ${name}.`);
                return;
            }
            action.setLoop(config.loopStyle, config.loopRepetitions);
            action.clampWhenFinished = config.clampWhenFinished;
            action.play();
        };
        this.configuration = (0, merge_configurations_1.mergeConfigurations)(defaultConfiguration, configuration);
        this.clips = this.configuration.clips.map(clip => THREE.AnimationClip.parse(clip));
        this.mixer = new THREE.AnimationMixer(rootObj);
    }
    update() {
        this.mixer.update(this.clock.getDelta());
    }
    stopAllAction() {
        this.mixer.stopAllAction();
    }
}
exports.AnimationManager = AnimationManager;
