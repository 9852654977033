import React from 'react';
import classNames from "classnames";
import './ar-link.scss'
import QrCodeCanvas from '../qr-code-canvas';

export interface IProps {
    href?: string,
    imageSrc?: string,
    className?: string,
    [index: string]: any,
}

const ARLink: React.FC<IProps> = ({href, imageSrc, className, style, ...props}) => {
    const styles = {
        ...style,
        opacity: !!href ? 1 : 0.1,
    }
    const classes = classNames('cl-ar-link', className);

    return (
        <a
            style={styles}
            className={classes}
            href={href}
            target="_blank"
            rel="noreferrer"
            {...props}
        >
            <QrCodeCanvas
                text={href ?? 'Not generated yet!'}
            />
        </a>
    );
}

export default ARLink