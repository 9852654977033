import * as t from './action-types'
import {AnyAction} from 'redux';
import {IState} from './types';

const initialState: IState = {
    loading: false,
    locale: 'de',
    translations: undefined,
}

const reducer = function (state: IState = initialState, action: AnyAction): IState {
    switch (action.type) {
        case t.SET_LOADING:

            return {
                ...state,
                loading: action.loading,
            };
        case t.SET_TRANSLATIONS:

            return {
                loading: false,
                locale: action.locale,
                translations: action.translations,
            }
    }

    return state
}

export default reducer;
