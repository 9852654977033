"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GltfExporter = void 0;
const { GLTFExporter } = require('../workaround-three-examples/GLTFExporter');
const merge_configurations_1 = require("../utils/merge-configurations");
const defaultConfiguration = {
    options: {
        trs: false,
        onlyVisible: true,
        binary: true,
        truncateDrawRange: true,
        maxTextureSize: 1024,
        animations: [],
        forceIndices: false,
        includeCustomExtensions: false,
        embedImages: true,
    },
    root: 'root',
    hidden: [],
    scale: 1.0,
};
class GltfExporter {
    constructor(scene, configuration = {}) {
        this.scene = scene;
        this.configuration = (0, merge_configurations_1.mergeConfigurations)(defaultConfiguration, configuration);
        this.origScaleVector = null;
    }
    export() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                const root = this.scene.getObjectByName(this.configuration.root);
                if (root) {
                    this.hide(this.configuration.hidden, false);
                    this.scale(root, false);
                    const gltfExporter = new GLTFExporter();
                    try {
                        gltfExporter.parse(root, (result) => {
                            resolve(new Blob([result], { type: 'application/octet-stream' }));
                            this.scale(root, true);
                            this.hide(this.configuration.hidden, true);
                        }, this.configuration.options);
                    }
                    catch (e) {
                        console.log(`ERROR: Can't export.`, e);
                        reject(e);
                    }
                }
                else {
                    console.log(`ERROR: Object with name ${this.configuration.root} not found.`);
                    reject(new Error(`ERROR: Object with name ${this.configuration.root} not found.`));
                }
            });
        });
    }
    scale(obj, restore) {
        if (!restore) {
            this.origScaleVector = obj.scale.clone();
            obj.scale.multiplyScalar(this.configuration.scale);
            obj.updateMatrixWorld();
        }
        else {
            if (!this.origScaleVector) {
                console.log('ERROR: Original scale values are not stored. Did you call scale with restore==false at first?');
                return;
            }
            obj.scale.copy(this.origScaleVector);
            obj.updateMatrixWorld();
            this.origScaleVector = null;
        }
    }
    hide(names, restore) {
        names.forEach((name) => {
            const object = this.scene.getObjectByName(name);
            if (object) {
                object.visible = restore;
            }
            else {
                console.log(`ERROR: Object with name ${name} not found.`);
            }
        });
    }
}
exports.GltfExporter = GltfExporter;
