"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Serializer = void 0;
class Serializer {
    serialize(obj) {
        return JSON.stringify(obj);
    }
    unserialize(serialized) {
        const wrapped = JSON.parse(serialized);
        return wrapped;
    }
}
exports.Serializer = Serializer;
