import React, {useEffect, useRef, useState} from 'react';
import {IconUpload} from "../icons/svgs";
import classNames from 'classnames'
import './assets-upload-input.scss'

interface IProps {
    className?: string,
    name: string,
    fileName: string|undefined,
    onUpload: (file: File) => void,
    [index: string]: any,
}

let counter = 0;

const AssetsUploadInput: React.FC<IProps> = ({fileName, name, className, onUpload, ...props}) => {
    const dropRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [over, setOver] = useState<boolean>(false);

    const changeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (null !== ev.target.files && ev.target.files.length > 0) {
            onUpload(ev.target.files[0]);
        }
    }

    useEffect(() => {
        if (dropRef && dropRef.current !== null && inputRef && inputRef.current !== null) {
            const dropZoneElement: HTMLDivElement = dropRef.current;
            const inputElement: HTMLInputElement = inputRef.current;

            const clickHandler = () => {
                inputElement.click();
            }

            const dragEnterHandler = (ev: Event) => {
                ev.preventDefault();
                counter++;
                setOver(true);
            }

            const dragOverHandler = (ev: Event) => {
                ev.preventDefault();
            }

            const dragLeaveHandler = (ev: Event) => {
                counter--;
                if (0 === counter) {
                    setOver(false);
                }
            }

            const dragEndHandler = (ev: Event) => {
                ev.preventDefault();
                counter = 0;
                setOver(false);
            }

            const dropHandler = (ev: any) => {
                ev.preventDefault();
                counter = 0;
                if (ev.dataTransfer && ev.dataTransfer.files.length > 0) {
                    onUpload(ev.dataTransfer.files[0]);
                }
                setOver(false);
            }

            dropZoneElement.addEventListener('click', clickHandler);
            dropZoneElement.addEventListener('dragenter', dragEnterHandler);
            dropZoneElement.addEventListener('dragover', dragOverHandler);
            dropZoneElement.addEventListener('dragleave', dragLeaveHandler);
            dropZoneElement.addEventListener('dragend', dragEndHandler);
            dropZoneElement.addEventListener('drop', dropHandler);

            return () => {
                dropZoneElement.removeEventListener('click', clickHandler)
                dropZoneElement.removeEventListener('dragenter', dragEnterHandler);
                dropZoneElement.removeEventListener('dragover', dragOverHandler);
                dropZoneElement.removeEventListener('dragleave', dragLeaveHandler);
                dropZoneElement.removeEventListener('dragend', dragEndHandler);
                dropZoneElement.removeEventListener('drop', dropHandler);
            }
        }
    }, [onUpload, fileName]);

    const classes = classNames(
        'cl-assetsUpload',
        over && 'cl-assetsUpload-over',
        fileName && 'cl-assetsUpload-changed',
        className
    );

    return (
        <div {...props} className={classes}>
            <div ref={dropRef} className="cl-assetsUpload-drop">
                <DefaultContent fileName={fileName} name={name}/>
            </div>
            <input
                onChange={changeHandler}
                style={styles.input}
                ref={inputRef}
                type="file"
            />
        </div>
    );
}

interface IDefaultContentProps {
    name: string,
    fileName: string|undefined
}

const DefaultContent: React.FC<IDefaultContentProps> = ({name, fileName }) => {

    return (
        <div className="cl-assetsUpload-text">
            <IconUpload/>
            <div className="cl-assetsUpload-title">{name}</div>
            <div className="cl-assetsUpload-desc">{fileName ? fileName : ''}</div>
        </div>
    )
}

const styles =
    {
        input: {
            display: 'none',
        }
    }

export default AssetsUploadInput