import React from 'react';
import Popup, {Position} from "./popup";
import DebugPopup from "./debug-popup";
import AssetsUploadMaterials from "./assets-upload-materials";
import AssetsUploadModels from "./assets-upload-models";
import AssetsUploadHdrConfigurator from "./assets-upload-hdr-configurator";
import AssetsUploadHdrRenderer from "./assets-upload-hdr-renderer";
import DebugModelsExport from './debug-models-export';

interface IProps {
    onClose: () => void,
}

const DebugPopupContainer: React.FC<IProps> = ({onClose}) => {
    const closeHandler = () => {
        onClose();
    }

    return (
        <Popup
            position={Position.right}
            onClose={closeHandler}
            fullHeight={false}
        >
            <DebugPopup>
                <DebugModelsExport/>
                <AssetsUploadMaterials/>
                <AssetsUploadModels/>
                <AssetsUploadHdrConfigurator/>
                <AssetsUploadHdrRenderer/>
            </DebugPopup>
        </Popup>
    );
}

export default DebugPopupContainer