import {getUrlParam} from "../helpers";

export const loadContext = () => {
    let context = getUrlParam('context');
    if (null === context) {
        context = process.env.REACT_APP_CL__CONTEXT ?? null;
    }

    if (null === context) {

        return;
    }

    if (!/^[0-9a-zA-Z_]+$/.test(context)) {
        console.log(`ERROR: Parameter context="${context}" contains illegal characters.`)

        return;
    }

    return context;
}

let cached: string | undefined = undefined;
export const context = () => {
    if (!cached) {
        cached = loadContext()
    }

    return cached;
}