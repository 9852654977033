import './annotation-button.scss';
import React from 'react';
import {RedCircle} from "../icons/svgs";
import classNames from 'classnames'

interface iProps {
    className?: string,
    onClick: () => void,
    [index: string]: any,
}

const AnnotationButton: React.FC<iProps> = ({className, ...props}) => {
    const classes = classNames(
        "cl-annotation-button",
        className
    )

    return (
        <div className={classes} {...props}>
            <div className="cl-annotation-buttonInner">
                <RedCircle/>
            </div>
        </div>
    )
};

export default AnnotationButton;
