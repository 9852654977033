"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiLineTextCanvasRenderer = void 0;
const merge_configurations_1 = require("../utils/merge-configurations");
const defaultConfiguration = {
    globalCompositeOperation: 'source-over',
    fontSize: 0.1,
    font: '%s serif',
    yOffsets: [0.15, 0.18],
};
class MultiLineTextCanvasRenderer {
    constructor(context, configuration = {}) {
        this.isTextWidthTooLong = (text, widthFactor = 0.95) => {
            const maxWidth = this.context.canvas.width * widthFactor;
            const textWidth = this.context.measureText(text).width;
            return textWidth <= maxWidth;
        };
        this.context = context;
        this.configuration = (0, merge_configurations_1.mergeConfigurations)(defaultConfiguration, configuration);
    }
    render(lines) {
        lines = lines.filter(line => line.length > 0);
        const width = this.context.canvas.width;
        const height = this.context.canvas.height;
        const fontSize = (width * this.configuration.fontSize) + 'px';
        const font = this.configuration.font.replace('%s', fontSize);
        this.context.font = font;
        const x = width / 2;
        let offset = 0.15;
        if (lines.length >= 2) {
            if (this.configuration.yOffsets.length > lines.length - 2) {
                offset = this.configuration.yOffsets[lines.length - 2];
            }
            else if (this.configuration.yOffsets.length > 0) {
                offset = this.configuration.yOffsets[this.configuration.yOffsets.length - 1];
            }
        }
        let y = height * (0.5 - (((lines.length / 2) - 0.5) * offset));
        for (let i = 0; i < lines.length; i++) {
            this.context.fillText(lines[i], x, y);
            y += height * offset;
        }
    }
}
exports.MultiLineTextCanvasRenderer = MultiLineTextCanvasRenderer;
