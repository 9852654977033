"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialFinder = void 0;
class MaterialFinder {
    constructor(scene) {
        this.scene = scene;
        this.cache = new Map();
        this.initialized = false;
    }
    getByName(name) {
        if (!this.initialized) {
            this.initializeCache();
            this.initialized = true;
        }
        const objects = this.cache.get(name);
        if (!objects) {
            console.warn(`ERROR: No objects found for material ${name}.`);
        }
        return objects !== null && objects !== void 0 ? objects : [];
    }
    initializeCache() {
        this.scene.traverse((child) => {
            // @ts-ignore
            if (child.isMesh) {
                const mesh = child;
                // @ts-ignore
                const name = mesh.material.name;
                if (!this.cache.has(name)) {
                    this.cache.set(name, []);
                }
                const objects = this.cache.get(name);
                if (!objects) {
                    throw new Error('Object not found.');
                }
                if (objects) {
                    objects.push(mesh);
                }
            }
        });
    }
}
exports.MaterialFinder = MaterialFinder;
