import React from 'react';
import {appEngine} from '../../services/engine-service';
import {IModelConfiguration} from "../../model.schema";
import IconTextButton from "../buttons/icon-text-button";

// @todo add correct types
interface IProps {
    children: React.ReactNode,
    configuration?: IModelConfiguration,
    lookupId: string,
    label: string,
    mutate: any,
    shrinkIfMobile: boolean,
    disabled?: boolean
}

const BooleanLookup: React.FC<IProps> = ({children, configuration, lookupId, mutate, shrinkIfMobile = false, disabled, ...props}) => {
    if (configuration === undefined) {

        return null;
    }
    const values = appEngine.getLookupValues(configuration, lookupId);

    const disable = values === undefined || values.length < 2 || disabled
    const selected = appEngine.getLookupValue(configuration, lookupId);

    return (
        <IconTextButton
            {...props}
            disabled={disable}
            isSelected={selected}
            onClick={() => {
                mutate(lookupId, !selected)
            }}
            shrinkIfMobile={shrinkIfMobile}
        >
            {children}
        </IconTextButton>

    );
};

export default BooleanLookup