import {createSelector} from 'reselect';
import {reduxName} from './constants'
import {useAppSelector} from '../../store';
import type {RootState} from '../../store';
import {translate} from "./functions";
import {IState} from './types'

// selectors
const getAll = (state: RootState): IState => (state[reduxName]);

export const loading = createSelector(
    getAll,
    (all) => all.loading
);

export const getTranslations = createSelector(
    getAll,
    data => data.translations
);

export const getLocale = createSelector(
    getAll,
    data => data.locale
);

export const getTrans = (state: RootState) => {

    return (key: string) => translate(getTranslations(state), key)
};

// hooks
export const useTrans = () => {

    return useAppSelector(state => getTrans(state))
};
