import { ILightBox, LightBoxMode } from '../../redux/storages/configuration-storage';
import { IConfigurationModel } from '../configuration-service';
import { dataUrlToFile, fileToDataUrl } from '@canvas-logic/configurator-base/lib/utils/file';

export interface IMapperResult {
  readonly cardDispenser: boolean,
  readonly printerShredder: boolean,
  readonly thermoPrinter: boolean,
  readonly keyboard: boolean,
  readonly scanner: boolean,
  readonly screenPrivacyFilter: boolean,
  readonly material: string,
  readonly double: boolean,
  readonly mount: string,
  readonly colorValue?: string,
  readonly legColorValue?: string,
  readonly lightBox?: ILightBoxData,
}

interface ILightBoxData {
  mode: LightBoxMode,
  file?: ILightBoxFile,
  text?: string[],
}

interface ILightBoxFile {
  readonly dataBase64: string,
  readonly fileName: string,
}

export class Mapper {
  async toResult(model: IConfigurationModel): Promise<IMapperResult> {
    let lightBox: ILightBoxData | undefined;
    if (model.lightBox) {
      let file: ILightBoxFile | undefined = undefined;
      let text: string[] | undefined = undefined;
      if (model.lightBox.mode === LightBoxMode.image && model.lightBox.image) {
        file = {
          dataBase64: await fileToDataUrl(model.lightBox.image!),
          fileName: model.lightBox.image.name ?? ''
        };
      }
      if (model.lightBox.mode === LightBoxMode.text) {
        text = model.lightBox.text;
      }

      lightBox = {
        mode: model.lightBox.mode,
        file,
        text
      };
    }

    const res: IMapperResult = {
      ...model,
      lightBox
    };

    return res;
  }

  fromResult(res: IMapperResult): IConfigurationModel {
    let lightBox: ILightBox | undefined = undefined;
    if (res.lightBox) {
      let image: File | undefined = undefined;
      if (res.lightBox.mode === LightBoxMode.image && res.lightBox.file) {
        image = dataUrlToFile(res.lightBox.file.dataBase64, res.lightBox.file.fileName);
      }
      lightBox = {
        mode: res.lightBox.mode,
        image,
        text: res.lightBox.text ?? []
      };
    }

    return {
      ...res,
      lightBox
    } as IConfigurationModel;
  }
}