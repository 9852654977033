import React, {useCallback, useEffect, useState} from 'react';
import "./debug-popup.scss"
import AssetsUploadInput from "../input/assets-upload-input";
import {useAppSelector} from "../../redux/store";
import * as cnfStorage from '../../redux/storages/configuration-storage'
import {viewerService} from "../../services/viewer-service";

interface IProps {}

let modelsUrl:any = undefined

const AssetsUploadModels: React.FC<IProps> = () => {
    const conf = useAppSelector(cnfStorage.selectors.getConfiguration);
    const [assetUrl, setAssetUrl] = useState<any>(modelsUrl)


    useEffect(() => {
            return () => {
                modelsUrl = assetUrl;
            };
        }, [assetUrl]
    )

    const onUpload = useCallback(async (file: File) => {
        setAssetUrl(file.name)
        if (undefined === conf) {

            return;
        }
        const url = URL.createObjectURL(file);
        await viewerService.reinit({}, {
            viewer: {
                modelsUrl: url,
            }
        })
        viewerService.showConfiguration(conf)
    },[conf])

    return (
        <AssetsUploadInput
            onUpload={onUpload}
            name={"models"}
            fileName={assetUrl}
        />
    );
}

export default AssetsUploadModels