"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createShareService = exports.createCanFileStorage = void 0;
const share_service_1 = require("./share-service");
const serializer_1 = require("./serializer");
const can_file_storage_1 = require("./storages/can-file-storage");
const embedded_url_helper_1 = require("./url-helpers/embedded-url-helper");
const configurator_url_helper_1 = require("./url-helpers/configurator-url-helper");
const createCanFileStorage = (keyPrefix, canFileRequests) => {
    return new can_file_storage_1.CanFileStorage(canFileRequests, keyPrefix);
};
exports.createCanFileStorage = createCanFileStorage;
const createShareService = (storage, embeddedSrv) => {
    return new share_service_1.ShareService(new serializer_1.Serializer(), embeddedSrv.embedded ? new embedded_url_helper_1.EmbeddedUrlHelper(embeddedSrv.parentLocation) : new configurator_url_helper_1.ConfiguratorUrlHelper(), storage, embeddedSrv);
};
exports.createShareService = createShareService;
