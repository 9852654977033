import React from 'react';
import classNames from 'classnames';
import './share-feedback-button.scss';
import IconButton from "./icon-button";
import LoadingRing from "../loading/loading-ring";

interface IProps {
    className?: string,
    title: string,
    iconNode: any,
    running: boolean,
    onClick: ()=>void,
    [index: string]: any,
    children?: React.ReactNode,
}

const ShareFeedbackButton: React.FC<IProps> = ({className, title, iconNode, running, onClick, children, ...props}) => {
    const classes = classNames(
        className,
        'cl-share-feedback');
    return (
        <div className={classes} {...props}>
            <IconButton
                className={'cl-share-feedback-button'}
                {...props}
                title={title}
                onClick={onClick}
                isSelected={false}
                disabled={running}
            >
                {
                    running
                        ? <LoadingRing size={26}/>
                        : iconNode
                }
            </IconButton>
            {children}
        </div>
    )
}

export default ShareFeedbackButton