import React, {useState} from 'react';
import './configurator.scss';
import {
    IconAr,
    IconBrush,
    IconSnapshot,
    NfcScanner,
    IconCardDispenser,
    IconKeyboard,
    IconLightbox,
    ThermoPrinter,
    PrinterShredder,
    IconUpload,
    IconScreenPrivacyFilter, IconBarCodeScanner, IconKioskColor, IconLegColor, IconDoubleSided
} from './icons/svgs';
import RenderControlButton from "./buttons/render-control-button";
import ColorLookup from "./lookup/color-lookup";
import {IModelConfiguration} from '../model.schema';
import {formatPrice} from "../services/price-service";
import ModelViewer from './model-viewer';
import {useAppDispatch, useAppSelector} from '../redux/store';
import * as appStorage from '../redux/storages/app-storage'
import * as cnfStorage from '../redux/storages/configuration-storage'
import HighResPopupContainer from './popup/high-res-popup-container';
import ArPopupContainer from './popup/ar-popup-container';
import {useTrans} from "../redux/storages/i18n-storage";
import ColorPickerPopupContainer from "./popup/color-picker-popup-container";
// import ColorPalettePopupContainer from "./popup/color-palette-popup-container";
import {useMaterialOptions} from "../hooks/use-material-options";
import ViewerMenu from "./viewer-menu";
import AnimatedHide, {Direction} from "./animated-hide";
import BooleanLookup from "./lookup/boolean-lookup";
// import SwitchLookup from "./lookup/switch-lookup";
import AppLayout from "./app-layout";
import {AnnotationConfigName} from "../services/viewer-service";
import LightBoxPopupContainer from "./popup/light-box-popup-container";
import ConfigurationMenu from "./configuration-menu";
import {useAppContext} from "../hooks/use-app-context";
import classNames from "classnames";
import DebugPopupContainer from "./popup/debug-popup-container";
import StartDebugButton from "./buttons/start-debug-button";
import ShareLinkButton from './share-link-button';
import SwitchLookup from './lookup/switch-lookup';
import { appEngine } from '../services/engine-service';

enum Popup {
    'none',
    'highRes',
    'color',
    'legColor',
    'ar',
    'debug'
}

const isLayoutOrientationHorizontal = false
const moreThan5MenuButtons = true //update breakpoint if > 6

interface IProps {
    configuration?: IModelConfiguration,
    mutate: (lookupId: string, value: any) => void,
}

interface PopupProps {
    dimensions: {
        width: number,
        height: number,
    }
}

const Configurator: React.FC<IProps> = ({configuration, mutate}) => {

    const isDevelopoment = 'on' === String(process.env.REACT_APP_CL__DEBUG_ENABLE_THREE_FILE_UPLOAD).toLowerCase();
    const dispatch = useAppDispatch();
    const [popup, setPopup] = useState<Popup>(Popup.none);
    const zoomed = useAppSelector(appStorage.selectors.zoomed);
    const selectedZoom = useAppSelector(appStorage.selectors.getSelectedZoom)
    const zoom = (zoom: AnnotationConfigName | undefined) => dispatch(appStorage.actions.zoom(zoom));
    const price = useAppSelector(cnfStorage.selectors.getTotalPrice);
    const t = useTrans();
    const currentMaterial = useAppSelector(cnfStorage.selectors.currentMaterial);
    const context = useAppContext();
    let disableDbl = false;
    let disableWallMount = false;
    if (configuration) {
        disableDbl = appEngine.getLookupValue(configuration!, 'model.mount') === 'wall_mount';
        disableWallMount = appEngine.getLookupValue(configuration!, 'model.double');
    }

    const renderPopups = ({dimensions}: PopupProps) => {
        return <>
            {
                popup === Popup.color && configuration &&
                <ColorPickerPopupContainer configuration={configuration} isBody={true} onClose={() => setPopup(Popup.none)}/>
            }
            {
                popup === Popup.legColor && configuration &&
                <ColorPickerPopupContainer configuration={configuration} isBody={false} onClose={() => setPopup(Popup.none)}/>
            }
            {
                popup === Popup.highRes &&
                <HighResPopupContainer dimensions={dimensions} onClose={() => setPopup(Popup.none)}/>
            }
            {
                popup === Popup.ar && <ArPopupContainer onClose={() => setPopup(Popup.none)}/>
            }
            {
                popup === Popup.debug && <DebugPopupContainer onClose={() => setPopup(Popup.none)}/>
            }
            {AnnotationConfigName.zoomPosLight === selectedZoom && (
                <LightBoxPopupContainer
                    onClose={() => {
                        zoom(undefined)
                    }}
                />
            )}
        </>
    }

    const renderViewport = () => {
        return <>
            <ModelViewer horizontal={isLayoutOrientationHorizontal}/>
            <AnimatedHide
                hidden={zoomed || popup === Popup.highRes || popup === Popup.ar}
                direction={isLayoutOrientationHorizontal ? Direction.top : Direction.left}
            >
                <ViewerMenu
                    horizontal={isLayoutOrientationHorizontal}
                    className="cl-configurator-topLeftMenu"
                >
                    {
                        currentMaterial && currentMaterial.model.color.enabled && (
                            <RenderControlButton
                                className="cl-configurator-brush"
                                onClick={() => setPopup(Popup.color)}
                                title={t("viewport.control.button.change.material.text")}
                                isSelected={false}
                            >
                                <IconKioskColor/>
                            </RenderControlButton>
                        )
                    }
                    {
                      currentMaterial && currentMaterial.model.color.enabled && (
                        <RenderControlButton
                          className="cl-configurator-brush"
                          onClick={() => setPopup(Popup.legColor)}
                          title={t("viewport.control.button.change.leg.material.text")}
                          isSelected={false}
                        >
                            <IconLegColor/>
                        </RenderControlButton>

                      )
                    }
                </ViewerMenu>
                <ViewerMenu
                    horizontal={isLayoutOrientationHorizontal}
                    className="cl-configurator-bottomLeftSpacer"
                >
                    <RenderControlButton
                      onClick={() => {
                          setPopup(Popup.ar)
                      }}
                      title={t("viewport.control.button.arview.text")}
                      isSelected={false}
                    >
                        <IconAr/>
                    </RenderControlButton>
                    <RenderControlButton
                        onClick={() => {
                            setPopup(Popup.highRes)
                        }}
                        title={t("viewport.control.button.snapshot.text")}
                        isSelected={false}
                    >
                        <IconSnapshot/>
                    </RenderControlButton>
                    <ShareLinkButton/>
                </ViewerMenu>
            </AnimatedHide>
        </>
    }

    const configurationAreaClasses = classNames(
        "cl-configuration-area ",
        moreThan5MenuButtons ? "cl-configuration-area-shrink" : ""
    )

    const renderConfiguration = () => {
        return <div className={configurationAreaClasses}>
            {
                isDevelopoment && <StartDebugButton
                    onClick={() => {
                        setPopup(Popup.debug)
                    }}
                    title={t("debug.start.button.text")}
                >
                    <IconUpload/>
                </StartDebugButton>
            }
            <div className="cl-configuration-head">
                {
                    context && (
                        <div className="cl-configuration-mainHeader">
                            {t(context.titleLangId)}
                        </div>
                    )
                }
                {
                    context && context.showPrice && (
                        <div className="cl-configuration-price">
                            {t("srp")}: {price !== undefined ? formatPrice(Number(price.toString()), t('locale'), t('currency')) : '-'}
                        </div>
                    )
                }
            </div>
            <ConfigurationMenu>
                <BooleanLookup
                    label={t("configuration.menu.component.button.cardDispenser")}
                    configuration={configuration}
                    mutate={mutate}
                    lookupId="model.cardDispenser"
                    shrinkIfMobile={moreThan5MenuButtons}
                >
                    <IconCardDispenser/>
                </BooleanLookup>
                <BooleanLookup
                    label={t("configuration.menu.component.button.printerShredder")}
                    configuration={configuration}
                    mutate={mutate}
                    lookupId="model.printerShredder"
                    shrinkIfMobile={moreThan5MenuButtons}
                >
                    <PrinterShredder/>
                </BooleanLookup>
                <BooleanLookup
                    label={t("configuration.menu.component.button.scanner")}
                    configuration={configuration}
                    mutate={mutate}
                    lookupId="model.scanner"
                    shrinkIfMobile={moreThan5MenuButtons}
                >
                    <IconBarCodeScanner/>
                </BooleanLookup>
                <BooleanLookup
                  label={t("configuration.menu.component.button.doublesided")}
                  configuration={configuration}
                  mutate={mutate}
                  lookupId="model.double"
                  shrinkIfMobile={moreThan5MenuButtons}
                  disabled={disableDbl}
                >
                    <IconDoubleSided/>
                </BooleanLookup>
                <div className="cl-configuration-header">
                Mounting type:
                </div>
                <SwitchLookup
                  configuration={configuration}
                  mutate={mutate}
                  lookupId="model.mount"
                  propertyPath="mount"
                  disabled={disableWallMount}
                  disabledOpt={'wall_mount'}
                />
            </ConfigurationMenu>
        </div>
    }

    return (
        <AppLayout
            renderPopups={renderPopups}
            renderViewport={renderViewport}
            renderConfiguration={renderConfiguration}
            enableMobileScrolling={context?.enableMobileScrolling}
        />
    );
}

export default Configurator