"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HexHashConverter = void 0;
class HexHashConverter {
    constructor(targetCharset) {
        this.targetCharset = targetCharset;
    }
    convert(hexStr) {
        const charNum = Math.ceil(HexHashConverter.log16(this.targetCharset.length));
        const q = Math.pow(16, charNum) / this.targetCharset.length;
        const splitted = [];
        let s = '';
        for (let i = 0; i < hexStr.length; i++) {
            s += hexStr[i];
            if (i % charNum === charNum - 1) {
                splitted.push(s);
                s = '';
            }
        }
        if (s.length > 0) {
            splitted.push(s);
        }
        let res = '';
        splitted.forEach(s => {
            const num = parseInt(s, 16);
            const index = Math.floor(num / q);
            res += this.targetCharset[index];
        });
        return res;
    }
    static log16(number) {
        return Math.log(number) / Math.log(16);
    }
}
exports.HexHashConverter = HexHashConverter;
