import React from 'react';
import './grabbing-overlay.scss';
import {useTrans} from "../redux/storages/i18n-storage";
import Overlay from './overlay';

interface IProps {
    onClose?: () => void,
    [index: string]: any,
}

const GrabbingOverlay: React.FC<IProps> = ({onClose, ...props}) => {
    const t = useTrans();

    return (
        <Overlay transparent={false} className="cl-grabbingOverlay" {...props}>
            <div onClick={onClose} className="cl-btn">
                {t('configurator.grabbing.help.overlay.button.text')}
            </div>
        </Overlay>
    );
}

export default GrabbingOverlay